import * as S from './styles';
import { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useUsuario } from '../../hooks/user';
import {
  TextField,
  FormControl,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  IconButton,
  FormControlLabel
} from '@material-ui/core';
import { Loading } from '../Loading/index';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useEmpresa } from '../../hooks/empresa';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { MensagemModal } from '../../components/MensagemModal/index';

import { useParams } from 'react-router-dom';

export function FormEditaUsuario() {
  const { editUsuario, isLoading, VerUsuarioPorUUID } = useUsuario();
  const { empresaListaAtiva } = useEmpresa();
  const { id } = useParams();

  const [nome, setNome] = useState(VerUsuarioPorUUID[0]?.nome || '');
  const [cpf, setCPF] = useState(VerUsuarioPorUUID[0]?.cpf?.br || '');
  const [email, setEmail] = useState(VerUsuarioPorUUID[0]?.email || '');
  const [status, setStatus] = useState(VerUsuarioPorUUID[0]?.status || '');
  const [telefone, setTelefone] = useState(
    VerUsuarioPorUUID[0]?.telefone?.br || ''
  );
  const [celular, setCelular] = useState(
    VerUsuarioPorUUID[0]?.celular?.br || ''
  );
  const [data_nascimento, setDataNascimento] = useState(
    VerUsuarioPorUUID[0]?.data_nascimento?.br || ''
  );
  const [empresa, setEmpresa] = useState(VerUsuarioPorUUID[0]?.empresa || '');
  const [loading, setLoading] = useState(false);

  const [abreMensagemModal, setModalMensagemModal] = useState(false);
  const [mensagemModal, setLinkMensagem] = useState(false);

  function handleAbreMensagemModal(mensagem) {
    setModalMensagemModal(true);
    setLinkMensagem(mensagem);
  }

  function handleFechaMensagemModal() {
    setModalMensagemModal(false);
    setLinkMensagem('');
  }

  useEffect(() => {
    setLoading(true);
    api
      .get('/usuario/' + id)
      .then(({ data }) => {
        setLoading(false);
        setCPF(data.cpf.br || '');
        setNome(data.nome || '');
        setCelular(data.celular.br || '');
        setTelefone(data.telefone.br || '');
        setCPF(data.cpf.br || '');
        setDataNascimento(data.data_nascimento.br || '');
        setStatus(data?.status || '');
        setEmail(data.email || '');
        setMaster(data?.master === 2 ? false : true);
        setAdministrador(data?.administrador === 2 ? false : true);
        setGerente(data?.gerente === 2 ? false : true);
        setGestor(data?.gestor === 2 ? false : true);
        setAuditor(data?.auditor === 2 ? false : true);
        setAnalista(data?.analista === 2 ? false : true);
        setEmpresa(data?.empresa || '');
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      })
      .finally((response) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlEditUser = async (event) => {
    event.preventDefault();

    let data = {
      nome,
      cpf,
      email,
      celular,
      nascimento: data_nascimento,
      status,
      telefone,
      empresa,
      master: master === false ? '2' : '1',
      administrador: administrador === false ? '2' : '1',
      gerente: gerente === false ? '2' : '1',
      gestor: gestor === false ? '2' : '1',
      auditor: auditor === false ? '2' : '1',
      analista: analista === false ? '2' : '1'
    };

    let result = await editUsuario(data, id);
    if (result.erro === false && result.lista !== '') {
      setCPF(result.lista?.cpf.br || '');
      setNome(result.lista?.nome || '');
      setCelular(result.lista?.celular.br || '');
      setTelefone(result.lista?.telefone.br || '');
      setCPF(result.lista?.cpf.br || '');
      setDataNascimento(result.lista?.data_nascimento.br || '');
      setStatus(result.lista?.status || '');
      setEmail(result.lista?.email || '');
      setEmpresa(result.lista?.empresa || '');
      setMaster(result.lista?.master === 2 ? false : true);
      setAdministrador(result.lista?.administrador === 2 ? false : true);
      setGerente(result.lista?.gerente === 2 ? false : true);
      setGestor(result.lista?.gestor === 2 ? false : true);
      setAuditor(result.lista?.auditor === 2 ? false : true);
      setAnalista(result.lista?.analista === 2 ? false : true);
    }
  };
  //administrador
  let master_atual = VerUsuarioPorUUID[0]?.master === 2 ? false : true;
  const [master, setMaster] = useState(master_atual || false);

  const handleMaster = (event) => {
    setMaster(event.target.checked);
  };

  //administrador
  let administrador_atual =
    VerUsuarioPorUUID[0]?.administrador === 2 ? false : true;
  const [administrador, setAdministrador] = useState(
    administrador_atual || false
  );

  const handleAdministrador = (event) => {
    setAdministrador(event.target.checked);
  };
  //Gerente
  let gerente_atual = VerUsuarioPorUUID[0]?.gerente === 2 ? false : true;
  const [gerente, setGerente] = useState(gerente_atual || false);

  const handleGerente = (event) => {
    setGerente(event.target.checked);
  };
  //gestor
  let gestor_atual = VerUsuarioPorUUID[0]?.gestor === 2 ? false : true;
  const [gestor, setGestor] = useState(gestor_atual || false);

  const handleGestor = (event) => {
    setGestor(event.target.checked);
  };
  //auditor
  let auditor_atual = VerUsuarioPorUUID[0]?.auditor === 2 ? false : true;
  const [auditor, setAuditor] = useState(auditor_atual || false);

  const handleAuditor = (event) => {
    setAuditor(event.target.checked);
  };
  //analista
  let analista_atual = VerUsuarioPorUUID[0]?.analista === 2 ? false : true;
  const [analista, setAnalista] = useState(analista_atual || false);

  const handleAnalista = (event) => {
    setAnalista(event.target.checked);
  };

  const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));

  return (
    <>
      <S.Container onSubmit={handlEditUser}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <S.ContainerCardForm>
              <div className="card-form">
                <Card className="item">
                  <CardContent>
                    <S.TituloTopo>Dados pessoais</S.TituloTopo>
                    <S.Column>
                      <FormControl>
                        <TextField
                          variant="outlined"
                          label={'Nome'}
                          value={nome}
                          placeholder="Nome"
                          onChange={(event) => setNome(event.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <InputMask
                          type="text"
                          mask="999.999.999-99"
                          placeholder="CPF"
                          onChange={(event) => setCPF(event.target.value)}
                          value={cpf}
                          maskChar=" "
                        >
                          {() => <TextField variant="outlined" label={'CPF'} />}
                        </InputMask>
                      </FormControl>
                      <FormControl>
                        <InputMask
                          type="text"
                          mask="99/99/9999"
                          placeholder="Data de nascimento"
                          onChange={(event) =>
                            setDataNascimento(event.target.value)
                          }
                          value={data_nascimento}
                          maskChar=" "
                        >
                          {() => (
                            <TextField
                              variant="outlined"
                              label={'Data de nascimento'}
                            />
                          )}
                        </InputMask>
                      </FormControl>
                      {usuarioPermissao === 1 ? (
                        <FormControl
                          className="form_control"
                          variant="outlined"
                        >
                          <InputLabel id="demo-simple-select-required-label">
                            Unidade
                          </InputLabel>
                          <Select
                            variant="outlined"
                            onChange={(event) => setEmpresa(event.target.value)}
                            value={empresa}
                            label={'Unidade'}
                            placeholder="Unidade"
                          >
                            <MenuItem value="">
                              <em>Selecione uma unidade</em>
                            </MenuItem>
                            {empresaListaAtiva.length > 0
                              ? empresaListaAtiva.map((lista) => (
                                  <MenuItem key={lista.uuid} value={lista.id}>
                                    {lista.nome}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      ) : null}
                    </S.Column>
                  </CardContent>
                </Card>
                <Card className="item">
                  <CardContent>
                    <S.TituloTopo>Dados de contato</S.TituloTopo>
                    <S.Column>
                      <FormControl>
                        <InputMask
                          type="text"
                          mask="(99) 9 9999-9999"
                          placeholder="Celular"
                          onChange={(event) => setCelular(event.target.value)}
                          value={celular}
                          maskChar=" "
                        >
                          {() => (
                            <TextField variant="outlined" label={'Celular'} />
                          )}
                        </InputMask>
                      </FormControl>
                      <FormControl>
                        <InputMask
                          type="text"
                          mask="(99) 9999-9999"
                          placeholder="Telefone"
                          onChange={(event) => setTelefone(event.target.value)}
                          value={telefone}
                          maskChar=" "
                        >
                          {() => (
                            <TextField variant="outlined" label={'Telefone'} />
                          )}
                        </InputMask>
                      </FormControl>
                    </S.Column>
                  </CardContent>
                </Card>
                <Card className="item">
                  <CardContent>
                    <S.TituloTopo>Acesso</S.TituloTopo>
                    <S.Column>
                      <FormControl>
                        <TextField
                          variant="outlined"
                          label={'E-mail'}
                          value={email}
                          placeholder="E-mail"
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </FormControl>

                      <h2>Permissões</h2>
                      <FormControl variant="outlined">
                        {usuarioPermissao === 1 ? (
                          <div
                            className="card-form"
                            style={{ marginTop: '20px' }}
                          >
                            <FormControl className="permissao">
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={master}
                                    onChange={handleMaster}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                }
                                label="Master"
                              />
                              <IconButton
                                onClick={(e) =>
                                  handleAbreMensagemModal(
                                    'Tem permissão total ao sistema para visualizar, criar, editar, excluir pastas, origens, usuários, checklists, tarefas de todas as unidades cadastradas.'
                                  )
                                }
                              >
                                <LiveHelpIcon />
                              </IconButton>
                            </FormControl>
                          </div>
                        ) : null}
                        <div
                          className="card-form"
                          style={{ marginTop: '20px' }}
                        >
                          <FormControl className="permissao">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={administrador}
                                  onChange={handleAdministrador}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="Administrador"
                            />
                            <IconButton
                              onClick={(e) =>
                                handleAbreMensagemModal(
                                  'Tem permissão total ao sistema para visualizar, criar, editar, excluir pastas, origens, usuários, checklists, tarefas da empresa na qual está vinculada.'
                                )
                              }
                            >
                              <LiveHelpIcon />
                            </IconButton>
                          </FormControl>
                        </div>
                        <div
                          className="card-form"
                          style={{ marginTop: '20px' }}
                        >
                          <FormControl className="permissao">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={gestor}
                                  onChange={handleGestor}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="Gestor"
                            />
                            <IconButton
                              onClick={(e) =>
                                handleAbreMensagemModal(
                                  'Tem permissão para visualizar, criar, editar, excluir usuários, checklists, tarefas da empresa na qual está vinculada.'
                                )
                              }
                            >
                              <LiveHelpIcon />
                            </IconButton>
                          </FormControl>
                        </div>
                        <div
                          className="card-form"
                          style={{ marginTop: '20px' }}
                        >
                          <FormControl className="permissao">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={auditor}
                                  onChange={handleAuditor}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="Auditor"
                            />
                            <IconButton
                              onClick={(e) =>
                                handleAbreMensagemModal(
                                  'Possui acesso apenas ao app (realizar auditorias).'
                                )
                              }
                            >
                              <LiveHelpIcon />
                            </IconButton>
                          </FormControl>
                        </div>
                        <div
                          className="card-form"
                          style={{ marginTop: '20px' }}
                        >
                          <FormControl className="permissao">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={analista}
                                  onChange={handleAnalista}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="Analista"
                            />
                            <IconButton
                              onClick={(e) =>
                                handleAbreMensagemModal(
                                  'Possui acesso apenas para visualização do sistema.'
                                )
                              }
                            >
                              <LiveHelpIcon />
                            </IconButton>
                          </FormControl>
                        </div>
                      </FormControl>
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-required-label">
                          Status
                        </InputLabel>
                        <Select
                          variant="outlined"
                          onChange={(event) => setStatus(event.target.value)}
                          value={status}
                          label={'Status'}
                          placeholder="Status"
                        >
                          <MenuItem value="">
                            <em>Selecione um status</em>
                          </MenuItem>
                          <MenuItem value={1}>Ativo</MenuItem>
                          <MenuItem value={2}>Inativo</MenuItem>
                        </Select>
                      </FormControl>
                    </S.Column>
                  </CardContent>
                </Card>
              </div>
            </S.ContainerCardForm>
            <button type="submit">
              {isLoading ? 'Carregando...' : 'Atualizar'}
            </button>
          </>
        )}
      </S.Container>
      {abreMensagemModal ? (
        <MensagemModal
          abre={abreMensagemModal}
          fecha={handleFechaMensagemModal}
          mensagem={mensagemModal}
        />
      ) : null}
    </>
  );
}
