import styled from 'styled-components'

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
    @media (max-width: 768px) {
	    flex-direction: column;
    }
    .MuiFormControl-root{
        margin-bottom: 20px;
    }
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-right: 5px;
    .MuiFormControl-root{
        margin-bottom: 8px;
    }
`;

export const Container = styled.form`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0px;

    label{
        color: #000;
    }


    input,
    select {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;
        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.25rem;
        }

    }
    .MuiSelect-selectMenu{
        border: 1px solid #d7d7d7;
        background: #e7e9ee;
        &::placeholder{
            color: var(--text-body);
        }
    }

    h2 {
        width: 100%;
        color: var(--red);
        font-size: 1.5rem;
        margin-bottom: 5px;
        border-bottom: 1px solid #d7d7d7;
        padding-left: 10px;
        padding-top: 20px;
    }

    button[type=submit] {
        width: 100%;
        max-width: 280px;
        padding: 0 1.5rem;
        height: 4rem;
        background: #33CC95;
        color: #FFFFFF;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.8rem;
        font-weight: 600;
        margin-left: 20px;
        cursor: pointer;
        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
        }
        @media (max-width: 768px) {
            margin-left: 0px;
        }
    }
`


export const ContainerSenha = styled.form`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem 1rem;
    margin-bottom: 50px;

    h2 {
        width: 100%;
        color: var(--red);
        font-size: 1.5rem;
        margin-bottom: 10px;
        border-bottom: 1px solid #d7d7d7;
        padding-left: 20px;
        padding-top: 20px;
    }

    label{
        color: #FFFFFF;
    }

    input[type=password],
    select  {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.25rem;
        }

    }

    button[type=submit] {
        width: 100%;
        max-width: 220px;
        padding: 0 1.5rem;
        height: 3rem;
        background: var(--green);
        color: #FFFFFF;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.8rem;
        font-weight: 600;
        margin-left: 18px;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
        }
        @media (max-width: 768px) {
            margin-left: 0px;
        }
    }
`

export const ContainerCardForm = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    .card-form {
        width: 100%;
        max-width: 1000px;
        display: flex;
        gap: 1rem;
        margin: 0px 1rem;
        .item{
            flex: 1;
            width: calc((100% / 2) - 20px);
        }
        .MuiFormControl-root.permissao{
            width: 100%;
            display: flex;
            flex-direction: row;

        }

        @media (max-width: 900px) {
            width: 100%;
            margin: 0px;
            flex-direction: column;
            h3{
                margin-top: 40px;
            }
            .item{
                flex: 1;
                width: 100%;
            }
        }
        .rs-uploader-picture .rs-uploader-trigger-btn {
            width: 150px;
            height: 150px;
        }
        .rs-uploader-picture .rs-uploader-trigger-btn {
            border-radius: 50%;
            border: solid 2px #CCC;
        }
        .rs-uploader-trigger-btn {
            img {
                object-fit: cover;
            }
        }
        .rs-icon-size-5x,
        .rs-icon-size-5x.rs-icon {
            font-size: 150px;
            height: 0px;
            margin-left: -2px;
            margin-top: -17px;
        }
        .rs-icon-size-5x, .cYHYoN .card-form .rs-icon-size-5x.rs-icon{
            margin-top: 0px !important;
        }
	

    }

`

export const ButtonImage = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: solid 2px #CCC;

    img {
        object-fit: cover;
    }
`

export const TituloTopo = styled.h1`
    width: 100%;
    color: var(--info-color);
    font-size: 1.2em;
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 5px;

`