import React, { useState } from 'react';
import * as S from './styles';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { SearchUsuarioModal } from '../../components/SearchUsuarioModal/index';
import { SearchEmpresaModal } from '../../components/SearchEmpresaModal';
import { SearchTarefaModal } from '../../components/SearchTarefaModal/index';
import { SearchTarefaDashboardModal } from '../../components/SearchTarefaDashboardModal/index';
import { DownloadRelatorioDashboardModal } from '../../components/DownloadRelatorioDashboardModal/index.js';
import { useUsuario } from '../../hooks/user';
import { usePasta } from '../../hooks/pasta';
import { useEstatistica } from '../../hooks/estatistica';
import { useEmpresa } from '../../hooks/empresa';

import { useParams } from 'react-router-dom';

const Topbar = ({ pageTitle }) => {
  let history = useHistory();

  const goToPreviousPathUser = () => {
    history.push('/usuario');
  };
  const goToPreviousPathCliente = () => {
    history.push('/pasta');
  };
  const goToPreviousPathTarefa = () => {
    history.push('/tarefa');
  };
  const goToPreviousPathBack = () => {
    history.goBack();
  };
  const goPesquisaUsuario = () => {
    handleAbreModalPesquisar();
  };
  const goPesquisaEmpresa = () => {
    handleAbreModalPesquisarEmpresa();
  };
  const goPesquisaTarefa = () => {
    handleAbreModalPesquisarTarefa();
  };

  const goPesquisaDashboardTarefa = () => {
    handleAbreModalPesquisarTarefaDashboard();
  };

  /* PESQUISA USUÁRIO */
  const buscaAtivaUsuario = localStorage.getItem('salvarBuscaUsuario');
  const [abreModalPesquisar, setAbreModalPesquisar] = useState(false);

  const { limpaUser } = useUsuario();
  var goLimpaUsuario;
  if (pageTitle && pageTitle === 'Usuário') {
    goLimpaUsuario = async () => {
      await limpaUser();
    };
  }
  function handleAbreModalPesquisar() {
    setAbreModalPesquisar(true);
  }

  function handleFechaPesquisarModal() {
    setAbreModalPesquisar(false);
  }

  /* PESQUISA UNIDADES */
  const buscaAtivaEmpresa = localStorage.getItem('salvarBuscaEmpresa');
  const [abreModalPesquisarEmpresa, setAbreModalPesquisarEmpresa] =
    useState(false);

  const { limpaEmpresa } = useEmpresa();
  var goLimpaEmpresa;
  if (pageTitle && pageTitle === 'Unidades') {
    goLimpaEmpresa = async () => {
      await limpaEmpresa();
    };
  }

  function handleAbreModalPesquisarEmpresa() {
    setAbreModalPesquisarEmpresa(true);
  }

  function handleFechaPesquisarModalEmpresa() {
    setAbreModalPesquisarEmpresa(false);
  }

  /* PESQUISA TAREFA */
  const buscaAtivaTarefa = localStorage.getItem('salvarBuscaTarefa');
  const [abreModalPesquisarTarefa, setAbreModalPesquisarTarefa] =
    useState(false);

  const { limpaTarefa } = usePasta();
  var goLimpaTarefa;
  const { id } = useParams();
  if (pageTitle && pageTitle === 'Editar pasta' && id !== '') {
    goLimpaTarefa = async () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await limpaTarefa(id);
    };
  }
  function handleAbreModalPesquisarTarefa() {
    setAbreModalPesquisarTarefa(true);
  }

  function handleFechaPesquisarTarefaModal() {
    setAbreModalPesquisarTarefa(false);
  }

  /* PESQUISA DASHBOARD TAREFA */
  const buscaAtivaTarefaDashboard = localStorage.getItem(
    'salvarBuscaDashboardTarefa'
  );
  const [
    abreModalPesquisarTarefaDashboard,
    setAbreModalPesquisarTarefaDashboard
  ] = useState(false);

  const { limpaTarefaDashboard } = useEstatistica();
  var goLimpaTarefaDashboard;
  if (pageTitle && pageTitle === 'Dashboard - Tarefas') {
    goLimpaTarefaDashboard = async () => {
      await limpaTarefaDashboard();
    };
  }
  function handleAbreModalPesquisarTarefaDashboard() {
    setAbreModalPesquisarTarefaDashboard(true);
  }

  function handleFechaPesquisarTarefaDashboardModal() {
    setAbreModalPesquisarTarefaDashboard(false);
  }

  /* Baixa relatório dashboard */
  const goDownloadRelatorioDashboard = () => {
    handleAbreModalRelatorioDashboard();
  };

  const [abreModalRelatorioDashboard, setAbreModalRelatorioDashboard] =
    useState(false);

  function handleAbreModalRelatorioDashboard() {
    setAbreModalRelatorioDashboard(true);
  }

  function handleFechaRelatorioDashboardModal() {
    setAbreModalRelatorioDashboard(false);
  }

  return (
    <>
      <S.TopbarStyle>
        <S.Title>
          <div className="esquerdo">
            {pageTitle &&
            (pageTitle === 'Cadastrar usuários' ||
              pageTitle === 'Editar usuário') ? (
              <BiIcons.BiArrowBack onClick={goToPreviousPathUser} />
            ) : null}
            {pageTitle &&
            (pageTitle === 'Cadastrar pastas' ||
              pageTitle === 'Editar pasta' ||
              pageTitle === 'Visualizar pasta') ? (
              <BiIcons.BiArrowBack onClick={goToPreviousPathCliente} />
            ) : null}
            {pageTitle &&
            (pageTitle === 'Cadastrar auditoria' ||
              pageTitle === 'Editar tarefa' ||
              pageTitle === 'Dashboard - Visitas' ||
              pageTitle === 'Cadastrar tarefas recorrentes' ||
              pageTitle === 'Visualizar checklist' ||
              pageTitle === 'Editar Auditoria' ||
              pageTitle === 'Visualizar Auditorias' ||
              pageTitle === 'Gerenciar') ? (
              <BiIcons.BiArrowBack onClick={goToPreviousPathBack} />
            ) : null}
            {pageTitle &&
            (pageTitle === 'Editar checklist' ||
              pageTitle === 'Respostas' ||
              pageTitle === 'Editar questionário' ||
              pageTitle === 'Visualizar grupo') ? (
              <BiIcons.BiArrowBack onClick={goToPreviousPathBack} />
            ) : null}
            {pageTitle && pageTitle === 'CheckLists - Tarefa' ? (
              <BiIcons.BiArrowBack onClick={goToPreviousPathTarefa} />
            ) : null}
            <h2>{pageTitle}</h2>
          </div>
          <div>
            {pageTitle && pageTitle === 'Usuário' ? (
              <Tooltip title="Pesquisar" placement="top">
                <span>
                  <BiIcons.BiSearchAlt2 onClick={goPesquisaUsuario} />
                </span>
              </Tooltip>
            ) : null}
            {buscaAtivaUsuario &&
            buscaAtivaUsuario !== '' &&
            pageTitle &&
            pageTitle === 'Usuário' ? (
              <Tooltip title="Limpar pesquisa" placement="top">
                <span>
                  <AiIcons.AiFillCloseSquare onClick={goLimpaUsuario} />
                </span>
              </Tooltip>
            ) : null}

            {pageTitle && pageTitle === 'Unidades' ? (
              <Tooltip title="Pesquisar" placement="top">
                <span>
                  <BiIcons.BiSearchAlt2 onClick={goPesquisaEmpresa} />
                </span>
              </Tooltip>
            ) : null}
            {buscaAtivaEmpresa &&
            buscaAtivaEmpresa !== '' &&
            pageTitle &&
            pageTitle === 'Unidades' ? (
              <Tooltip title="Limpar pesquisa" placement="top">
                <span>
                  <AiIcons.AiFillCloseSquare onClick={goLimpaEmpresa} />
                </span>
              </Tooltip>
            ) : null}
            {buscaAtivaTarefa &&
            buscaAtivaTarefa !== '' &&
            pageTitle &&
            pageTitle === 'Editar pasta' ? (
              <Tooltip title="Limpar pesquisa" placement="top">
                <span>
                  <AiIcons.AiFillCloseSquare onClick={goLimpaTarefa} />
                </span>
              </Tooltip>
            ) : null}
            {pageTitle && pageTitle === 'Dashboard - Tarefas' ? (
              <>
                <Tooltip title="Pesquisar" placement="top">
                  <span>
                    <BiIcons.BiSearchAlt2 onClick={goPesquisaDashboardTarefa} />
                  </span>
                </Tooltip>
              </>
            ) : null}
            {pageTitle && pageTitle === 'Dashboard - Visitas' ? (
              <>
                <Tooltip title="Voltar" placement="top">
                  <span>
                    <AiIcons.AiOutlineDownload
                      onClick={() => goDownloadRelatorioDashboard()}
                    />
                  </span>
                </Tooltip>
              </>
            ) : null}
            {buscaAtivaTarefaDashboard &&
            buscaAtivaTarefaDashboard !== '' &&
            pageTitle &&
            pageTitle === 'Dashboard - Tarefas' ? (
              <Tooltip title="Limpar pesquisa" placement="top">
                <span>
                  <AiIcons.AiFillCloseSquare onClick={goLimpaTarefaDashboard} />
                </span>
              </Tooltip>
            ) : null}
          </div>
        </S.Title>
      </S.TopbarStyle>

      {abreModalPesquisar ? (
        <SearchUsuarioModal
          abre={abreModalPesquisar}
          fecha={handleFechaPesquisarModal}
        />
      ) : null}
      {abreModalPesquisarEmpresa ? (
        <SearchEmpresaModal
          abre={abreModalPesquisarEmpresa}
          fecha={handleFechaPesquisarModalEmpresa}
        />
      ) : null}
      {abreModalPesquisarTarefa ? (
        <SearchTarefaModal
          abre={abreModalPesquisarTarefa}
          fecha={handleFechaPesquisarTarefaModal}
        />
      ) : null}
      {abreModalPesquisarTarefaDashboard ? (
        <SearchTarefaDashboardModal
          abre={abreModalPesquisarTarefaDashboard}
          fecha={handleFechaPesquisarTarefaDashboardModal}
        />
      ) : null}
      {abreModalRelatorioDashboard ? (
        <DownloadRelatorioDashboardModal
          abre={abreModalRelatorioDashboard}
          fecha={handleFechaRelatorioDashboardModal}
        />
      ) : null}
    </>
  );
};

export default Topbar;
