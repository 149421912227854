import * as S from './styles';
import { useState } from 'react';
import { useCliente } from '../../hooks/cliente';
import {
  TextField,
  FormControl,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { useEmpresa } from '../../hooks/empresa';

export function FormCadastraCliente() {
  const { createCliente, isLoading } = useCliente();
  const { empresaListaAtiva } = useEmpresa();

  const [nome, setNome] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [status, setStatus] = useState('');

  const handlCreateUser = async (event) => {
    event.preventDefault();

    let data = {
      nome,
      empresa,
      status
    };

    let result = await createCliente(data);

    if (result.erro === false) {
      setNome('');
      setStatus('');
    }
  };
  const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));

  return (
    <S.Container onSubmit={handlCreateUser}>
      <S.ContainerCardForm>
        <div className="card-form">
          <Card className="item">
            <CardContent>
              <S.TituloTopo>Dados</S.TituloTopo>
              <S.Column>
                <FormControl>
                  <TextField
                    variant="outlined"
                    label={'Nome da Pasta'}
                    value={nome}
                    placeholder="Nome da Pasta"
                    onChange={(event) => setNome(event.target.value)}
                  />
                </FormControl>
                {usuarioPermissao === 1 ? (
                  <FormControl className="form_control" variant="outlined">
                    <InputLabel id="demo-simple-select-required-label">
                      Unidade
                    </InputLabel>
                    <Select
                      variant="outlined"
                      onChange={(event) => setEmpresa(event.target.value)}
                      value={empresa}
                      label={'Unidade'}
                      placeholder="Unidade"
                    >
                      <MenuItem value="">
                        <em>Selecione uma unidade</em>
                      </MenuItem>
                      {empresaListaAtiva.length > 0
                        ? empresaListaAtiva.map((lista) => (
                            <MenuItem key={lista.uuid} value={lista.id}>
                              {lista.nome}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                ) : null}
              </S.Column>
            </CardContent>
          </Card>
          <Card className="item">
            <CardContent>
              <S.TituloTopo>Acesso</S.TituloTopo>
              <S.Column>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-required-label">
                    Status
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setStatus(event.target.value)}
                    value={status}
                    label={'Status'}
                    placeholder="Status"
                  >
                    <MenuItem value="">
                      <em>Selecione um status</em>
                    </MenuItem>
                    <MenuItem value={1}>Ativo</MenuItem>
                    <MenuItem value={2}>Inativo</MenuItem>
                  </Select>
                </FormControl>
              </S.Column>
            </CardContent>
          </Card>
        </div>
      </S.ContainerCardForm>
      <button type="submit">{isLoading ? 'Carregando...' : 'Cadastrar'}</button>
    </S.Container>
  );
}
