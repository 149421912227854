import axios from 'axios';
import { getToken } from './auth';
const api = axios.create({
  baseURL: 'https://vistoria-api.bombeiros.to.gov.br/api',
  headers: {
    'Content-Type': 'application/json',
    CLIENTE: 'cbmto',
  }
});

// Middleware: Requisição - Token
// Realiza ação em todas as requisições, no caso, envia o Token
api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data?.error === 5401) {
      localStorage.clear();
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
