import * as S from './styles';
import { useState, useEffect } from 'react';
import { usePasta } from '../../hooks/pasta';
import CloseIcon from '@material-ui/icons/Close';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ButtonGroup,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';
import InputMask from 'react-input-mask';

import { useParams } from 'react-router-dom';

import api from '../../services/api';

export function SearchTarefaModal({ abre, fecha }) {
  const { id } = useParams();
  const { searchTarefa } = usePasta();
  const [origemListaAtiva, setOrigemListaAtiva] = useState([]);
  const [clienteListaAtiva, setClienteListaAtiva] = useState([]);

  const [acao, setAcao] = useState('');
  const [cliente, setCliente] = useState('');
  const [status, setStatus] = useState('');
  const [recorrente, setRecorrente] = useState('');
  const [inicio_previsto_final, setConclusaoPrevista] = useState('');
  const [inicio_previsto_inicio, setInicioPrevisto] = useState('');
  const [origem, setOrigem] = useState('');

  const [isLoadingOrigem, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get('/origem?status=1')
      .then(({ data }) => {
        setLoading(false);
        setOrigemListaAtiva(data);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally((response) => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get('/cliente?status=1')
      .then(({ data }) => {
        setLoading(false);
        setClienteListaAtiva(data.itens);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally((response) => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePesquisa = async (event) => {
    event.preventDefault();

    let data = {
      acao,
      cliente,
      recorrente,
      inicio_previsto_inicio,
      inicio_previsto_final,
      origem,
      status
    };

    await searchTarefa(data, fecha, id);
  };

  return (
    <Dialog open={abre} onClose={fecha} className="modal_dialog">
      <S.Container onSubmit={handlePesquisa}>
        <DialogTitle className="titulo">
          Pesquise
          <IconButton className="fecha" onClick={fecha}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className="grid_container">
          <Grid container>
            <Grid item xs={12}>
              <S.Column>
                <FormControl className="form_control">
                  <TextField
                    variant="outlined"
                    label={'Nome'}
                    value={acao}
                    placeholder="Nome"
                    onChange={(event) => setAcao(event.target.value)}
                  />
                </FormControl>
                {/* <FormControl  className="form_control" variant="outlined"  >
                                    <InputLabel id="demo-simple-select-required-label">Origem</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={event => setOrigem(event.target.value)}
                                        value={origem}
                                        label={'Origem'}
                                        placeholder="Origem"
                                    >
                                        <MenuItem value="">
                                            <em>{isLoadingOrigem === true ? 'Carregando lista' : 'Selecione uma origem'}</em>
                                        </MenuItem>
                                        {origemListaAtiva?.itens ? origemListaAtiva?.itens.map(lista => (
                                            <MenuItem key={`status${lista.id}`} value={lista.id} >{lista.titulo}</MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>  */}

                <FormControl className="form_control">
                  <InputMask
                    type="text"
                    mask="99/99/9999"
                    placeholder="Início de previsto"
                    onChange={(event) => setInicioPrevisto(event.target.value)}
                    value={inicio_previsto_inicio}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        variant="outlined"
                        label={'Início de previsto'}
                      />
                    )}
                  </InputMask>
                </FormControl>

                <FormControl className="form_control">
                  <InputMask
                    type="text"
                    mask="99/99/9999"
                    placeholder="Conclusão prevista"
                    onChange={(event) =>
                      setConclusaoPrevista(event.target.value)
                    }
                    value={inicio_previsto_final}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        variant="outlined"
                        label={'Conclusão prevista'}
                      />
                    )}
                  </InputMask>
                </FormControl>

                <FormControl variant="outlined" className="form_control">
                  <InputLabel id="demo-simple-select-required-label">
                    Cliente
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setCliente(event.target.value)}
                    value={cliente}
                    label={'Cliente'}
                    placeholder="Cliente"
                  >
                    <MenuItem value="">
                      <em>Selecione um cliente</em>
                    </MenuItem>
                    {clienteListaAtiva
                      ? clienteListaAtiva.map((lista) => (
                          <MenuItem
                            key={`status${lista.uuid}`}
                            value={lista.id}
                          >
                            {lista.nome}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>

                <FormControl className="form_control" variant="outlined">
                  <InputLabel id="demo-simple-select-required-label">
                    Status
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setStatus(event.target.value)}
                    value={status}
                    label={'Status'}
                    placeholder="Status"
                  >
                    <MenuItem key="1" value="">
                      <em>Selecione um status</em>
                    </MenuItem>
                    <MenuItem key="2" value="1">
                      <em>Pendente</em>
                    </MenuItem>
                    <MenuItem key="3" value="2">
                      <em>Concluída</em>
                    </MenuItem>
                    <MenuItem key="4" value="3">
                      <em>Atrasada</em>
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl className="form_control" variant="outlined">
                  <InputLabel id="demo-simple-select-required-label">
                    Recorrente
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setRecorrente(event.target.value)}
                    value={recorrente}
                    label={'Recorrente'}
                    placeholder="Recorrente"
                  >
                    <MenuItem value="">
                      <em>Selecione se deseja pesquisar por recorrente</em>
                    </MenuItem>
                    <MenuItem value={1}>Recorrente</MenuItem>
                    <MenuItem value={2}>Não recorrente</MenuItem>
                  </Select>
                </FormControl>
              </S.Column>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="container_acao">
          <ButtonGroup
            orientation="horizontal"
            color="primary"
            className="buttonGroup"
            aria-label="vertical outlined primary button group"
          >
            <Button className="botao edit" variant="contained" type="submit">
              Pesquisar
            </Button>
          </ButtonGroup>
        </DialogActions>
      </S.Container>
    </Dialog>
  );
}
