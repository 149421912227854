import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Nav = styled.div`
  max-width: 228px;
  background: #bd0007;
  height: 80px;
  display: none;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1300px) {
    width: 100px;
    display: flex;
    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0px;
      margin-left: 0px;
      padding-top: 0px;
      padding-left: 0px;
    }
  }
`;

export const NavIcon = styled(Link)`
  max-width: 228px;
  margin-left: 2rem;
  font-size: 2rem;
  display: none;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-top: 20px;
  @media (max-width: 1300px) {
    width: 100%;
    display: flex;
    background: #bd0007;
    margin-left: 0px;
    margin-top: 0px;
    padding-top: 20px;
    padding-left: 10px;
  }
`;

export const NavIconFechar = styled.a`
  width: 228px;
  height: 80px;
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  color: #fff;
  z-index: 100;
  margin-left: 10px;
  cursor: pointer;
  p {
    margin-left: 10px;
  }
`;

export const SidebarNav = styled.nav`
  background: #bd0007;
  width: 228px;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  padding: 0px 10px;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  z-index: 10;
  overflow: auto;
`;

export const SombraResponsive = styled.nav`
  background: #bd0007;
  width: 228px;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  padding: 0px 10px;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  z-index: 10;
  @media (max-width: 1300px) {
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    transition: none;
    padding: 0px;
    cursor: pointer;
  }
`;

export const SidebarWrap = styled.div`
  width: 100%;
  position: relative;
  z-index: 9999;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo img {
    text-align: center;
    width: 120px;
    margin-top: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 10px;
  }
  .nav-user {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
    .MuiListItemText-root {
      color: #ffffff;
    }
    svg {
      fill: #ffffff;
    }
    .MuiCollapse-root {
      width: 100%;
      padding-left: 20px;
    }
  }
  @media (max-width: 1300px) {
    width: 100%;
    max-width: 228px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
    background: #bd0007;
    left: 0px;
    transition: 350ms;
    a {
      width: 90%;
    }
  }
`;
