import * as S from './styles';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useUsuario } from '../../hooks/user';
import { useEmpresa } from '../../hooks/empresa';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import {
  TextField,
  FormControl,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  IconButton,
  Switch
} from '@material-ui/core';
import { MensagemModal } from '../../components/MensagemModal/index';

export function FormCadastraUsuario() {
  const { createUsuario, isLoading } = useUsuario();
  const { empresaListaAtiva } = useEmpresa();

  const [nome, setNome] = useState('');
  const [cpf, setCPF] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [data_nascimento, setDataNascimento] = useState('');
  const [senha, setSenha] = useState('');
  const [tipo, setTipo] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [status, setStatus] = useState('');
  const [abreMensagemModal, setModalMensagemModal] = useState(false);
  const [mensagemModal, setLinkMensagem] = useState(false);

  function handleAbreMensagemModal(mensagem) {
    setModalMensagemModal(true);
    setLinkMensagem(mensagem);
  }

  function handleFechaMensagemModal() {
    setModalMensagemModal(false);
    setLinkMensagem('');
  }

  const handlCreateUser = async (event) => {
    event.preventDefault();

    let data = {
      nome,
      cpf,
      email,
      celular: celular.replace(/\D/g, ''),
      nascimento: data_nascimento,
      password: senha,
      status,
      tipo,
      empresa,
      telefone: telefone.replace(/\D/g, ''),
      master: master === false ? '2' : '1',
      administrador: administrador === false ? '2' : '1',
      gerente: gerente === false ? '2' : '1',
      gestor: gestor === false ? '2' : '1',
      auditor: auditor === false ? '2' : '1',
      analista: analista === false ? '2' : '1'
    };

    let result = await createUsuario(data);

    if (result.erro === false) {
      setNome('');
      setCPF('');
      setEmail('');
      setTelefone('');
      setDataNascimento('');
      setCelular('');
      setSenha('');
      setTipo('');
      setStatus('');
      setEmpresa('');
    }
  };
  //master
  const [master, setMaster] = useState(false);

  const handleMaster = (event) => {
    setMaster(event.target.checked);
  };
  //administrador
  const [administrador, setAdministrador] = useState(false);

  const handleAdministrador = (event) => {
    setAdministrador(event.target.checked);
  };
  //Gerente
  const [gerente, setGerente] = useState(false);

  const handleGerente = (event) => {
    setGerente(event.target.checked);
  };
  //gestor
  const [gestor, setGestor] = useState(false);

  const handleGestor = (event) => {
    setGestor(event.target.checked);
  };
  //auditor
  const [auditor, setAuditor] = useState(false);

  const handleAuditor = (event) => {
    setAuditor(event.target.checked);
  };
  //analista
  const [analista, setAnalista] = useState(false);

  const handleAnalista = (event) => {
    setAnalista(event.target.checked);
  };

  const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));

  return (
    <>
      <S.Container onSubmit={handlCreateUser}>
        <S.ContainerCardForm>
          <div className="card-form">
            <Card className="item">
              <CardContent>
                <S.TituloTopo>Dados pessoais</S.TituloTopo>
                <S.Column>
                  <FormControl>
                    <TextField
                      variant="outlined"
                      label={'Nome'}
                      value={nome}
                      placeholder="Nome"
                      onChange={(event) => setNome(event.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      placeholder="CPF"
                      onChange={(event) => setCPF(event.target.value)}
                      value={cpf}
                      maskChar=" "
                    >
                      {() => <TextField variant="outlined" label={'CPF'} />}
                    </InputMask>
                  </FormControl>
                  <FormControl>
                    <InputMask
                      type="text"
                      mask="99/99/9999"
                      placeholder="Data de nascimento"
                      onChange={(event) =>
                        setDataNascimento(event.target.value)
                      }
                      value={data_nascimento}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          label={'Data de nascimento'}
                        />
                      )}
                    </InputMask>
                  </FormControl>
                  {usuarioPermissao === 1 ? (
                    <FormControl className="form_control" variant="outlined">
                      <InputLabel id="demo-simple-select-required-label">
                        Unidade
                      </InputLabel>
                      <Select
                        variant="outlined"
                        onChange={(event) => setEmpresa(event.target.value)}
                        value={empresa}
                        label={'Unidade'}
                        placeholder="Unidade"
                      >
                        <MenuItem value="">
                          <em>Selecione uma unidade</em>
                        </MenuItem>
                        {empresaListaAtiva.length > 0
                          ? empresaListaAtiva.map((lista) => (
                              <MenuItem key={lista.uuid} value={lista.id}>
                                {lista.nome}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  ) : null}
                </S.Column>
              </CardContent>
            </Card>
            <Card className="item">
              <CardContent>
                <S.TituloTopo>Dados de contato</S.TituloTopo>
                <S.Column>
                  <FormControl>
                    <InputMask
                      type="text"
                      mask="(99) 9 9999-9999"
                      placeholder="Celular"
                      onChange={(event) => setCelular(event.target.value)}
                      value={celular}
                      maskChar=" "
                    >
                      {() => <TextField variant="outlined" label={'Celular'} />}
                    </InputMask>
                  </FormControl>
                  <FormControl>
                    <InputMask
                      type="text"
                      mask="(99) 9999-9999"
                      placeholder="Telefone"
                      onChange={(event) => setTelefone(event.target.value)}
                      value={telefone}
                      maskChar=" "
                    >
                      {() => (
                        <TextField variant="outlined" label={'Telefone'} />
                      )}
                    </InputMask>
                  </FormControl>
                </S.Column>
              </CardContent>
            </Card>
            <Card className="item">
              <CardContent>
                <S.TituloTopo>Acesso</S.TituloTopo>
                <S.Column>
                  <FormControl>
                    <TextField
                      variant="outlined"
                      label={'E-mail'}
                      value={email}
                      placeholder="E-mail"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      variant="outlined"
                      label={'Senha'}
                      value={senha}
                      type="password"
                      placeholder="Senha"
                      onChange={(event) => setSenha(event.target.value)}
                    />
                  </FormControl>
                  <h2>Permissões</h2>
                  <FormControl variant="outlined">
                    {usuarioPermissao === 1 ? (
                      <div className="card-form" style={{ marginTop: '20px' }}>
                        <FormControl className="permissao">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={master}
                                onChange={handleMaster}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label="Master"
                          />
                          <IconButton
                            onClick={(e) =>
                              handleAbreMensagemModal(
                                'Tem permissão total ao sistema para visualizar, criar, editar, excluir pastas, origens, usuários, checklists, tarefas de todas as unidades cadastradas.'
                              )
                            }
                          >
                            <LiveHelpIcon />
                          </IconButton>
                        </FormControl>
                      </div>
                    ) : null}
                    <div className="card-form" style={{ marginTop: '20px' }}>
                      <FormControl className="permissao">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={administrador}
                              onChange={handleAdministrador}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Administrador"
                        />
                        <IconButton
                          onClick={(e) =>
                            handleAbreMensagemModal(
                              'Tem permissão total ao sistema para visualizar, criar, editar, excluir pastas, origens, usuários, checklists, tarefas da empresa na qual está vinculada'
                            )
                          }
                        >
                          <LiveHelpIcon />
                        </IconButton>
                      </FormControl>
                    </div>
                    <div className="card-form" style={{ marginTop: '20px' }}>
                      <FormControl className="permissao">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={gestor}
                              onChange={handleGestor}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Gestor"
                        />
                        <IconButton
                          onClick={(e) =>
                            handleAbreMensagemModal(
                              'Tem permissão para visualizar, criar, editar, excluir usuários, checklists, tarefas da empresa na qual está vinculada.'
                            )
                          }
                        >
                          <LiveHelpIcon />
                        </IconButton>
                      </FormControl>
                    </div>
                    <div className="card-form" style={{ marginTop: '20px' }}>
                      <FormControl className="permissao">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={auditor}
                              onChange={handleAuditor}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Auditor"
                        />
                        <IconButton
                          onClick={(e) =>
                            handleAbreMensagemModal(
                              'Possui acesso apenas ao app (realizar auditorias).'
                            )
                          }
                        >
                          <LiveHelpIcon />
                        </IconButton>
                      </FormControl>
                    </div>
                    <div className="card-form" style={{ marginTop: '20px' }}>
                      <FormControl className="permissao">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={analista}
                              onChange={handleAnalista}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Analista"
                        />
                        <IconButton
                          onClick={(e) =>
                            handleAbreMensagemModal(
                              'Possui acesso apenas para visualização do sistema.'
                            )
                          }
                        >
                          <LiveHelpIcon />
                        </IconButton>
                      </FormControl>
                    </div>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-required-label">
                      Status
                    </InputLabel>
                    <Select
                      variant="outlined"
                      onChange={(event) => setStatus(event.target.value)}
                      value={status}
                      label={'Status'}
                      placeholder="Status"
                    >
                      <MenuItem value="">
                        <em>Selecione um status</em>
                      </MenuItem>
                      <MenuItem value={1}>Ativo</MenuItem>
                      <MenuItem value={2}>Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </S.Column>
              </CardContent>
            </Card>
          </div>
        </S.ContainerCardForm>
        <button type="submit">
          {isLoading ? 'Carregando...' : 'Cadastrar'}
        </button>
      </S.Container>
      {abreMensagemModal ? (
        <MensagemModal
          abre={abreMensagemModal}
          fecha={handleFechaMensagemModal}
          mensagem={mensagemModal}
        />
      ) : null}
    </>
  );
}
