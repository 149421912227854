import React, { useRef } from "react"
import * as S from "./styles";
import { useState, useEffect, useCallback } from 'react';
import { useTarefa } from "../../hooks/tarefa"
import { useHistorico } from "../../hooks/historico"
import { useTag } from "../../hooks/tag"
import { useEmail } from "../../hooks/email"
import logo from '../../assets/logo-soul-malls.svg';

import ReactToPrint from "react-to-print";

import { Dialog, DialogTitle, DialogContent,  FormControl, Card, CardContent, InputLabel, Paper, Grid, IconButton, Accordion, AccordionSummary, ImageList, ImageListItem, Box, Slide, Typography, Tabs, Tab, ListItem, List, ListItemText,ListItemAvatar, Avatar, Divider, Switch, FormControlLabel } from "@material-ui/core";
import { Loading } from "../Loading/index";
import CloseIcon from '@material-ui/icons/Close';
import { useOrigem } from "../../hooks/origem";
import { HistoricoComponent } from "../HistoricoComponent/index"

import PrintIcon from '@material-ui/icons/Print';

import { ImagemModal } from "../../components/ImagemModal/index";

import { EditaTagModal } from "../../components/EditaTagModal/index";
import EditIcon from '@material-ui/icons/Edit';

import {
    useParams,
    useHistory
  } from "react-router-dom";

import CloudDownload from "@material-ui/icons/CloudDownload";
import Draggable from 'react-draggable';
// import { TrendingUpRounded } from "@material-ui/icons";
import moment from 'moment';

import { PieChart, Pie, Cell } from "recharts";

moment.locale('pt-br');
function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8A2BE2", "#DC143C", "#B8860B", "#C71585", "#D3D3D3", "#FFFF00", "	#808000"];

const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    payload
    }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        >
        {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export function VisualizaTarefa() {
    let componentRef = useRef();
    const { isLoadingEditID, VerTarefaPorUUID, VisualizarTarefaPorUUID, verImagemTarefa, isLoadingUpload,tarefaImagem, VisualizarRespostaPorUUID, VerRespostaPorUUID } = useTarefa();
    const { id } = useParams();
    const { origemLista } = useOrigem();
    const { emailPorUUID, VisualizarEmailPorUUID } = useEmail();

    const { historicoPorUUID, visualizarHistoricoPorUUID } = useHistorico();
    
    const [openConfirmImagem, setOpenConfirmarImagem] = useState(false);
    const [imagemModal, setModalImagem] = useState('');

    
    const {  tagPorUUID, VisualizarTagPorUUID } = useTag();

    const [imagem, setImagem] = useState(tarefaImagem[0] ||  '');

    const [recorrente, setRecorrente] = useState(VerTarefaPorUUID[0]?.recorrente || null);
    const [dia, setCada] = useState(VerTarefaPorUUID[0]?.recorrente?.dia || '');
    const [tipo, setFrequencia] = useState(VerTarefaPorUUID[0]?.recorrente?.tipo || '');
    const [dias_semana, setDiaSemana] = useState(String(VerTarefaPorUUID[0]?.recorrente?.dias_semana) || []);


    const master = Number(localStorage.getItem('MMPROWORK'));
    const administrador = Number(localStorage.getItem('MPROWORK'));
    const gerente = Number(localStorage.getItem('GTPROWORK'));
    const gestor = Number(localStorage.getItem('GSPROWORK'));
    const analista = Number(localStorage.getItem('ANPROWORK'));
    

    const handleListaTag = useCallback(async () => {
        await VisualizarTagPorUUID(VerTarefaPorUUID[0]?.uuid);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VerTarefaPorUUID[0]?.uuid]);

    const handleTarefa = useCallback(async () => {
        await VisualizarTarefaPorUUID(id);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleTarefa();
    }, [handleTarefa]);

    
    const handleEmail = useCallback(async () => {
        await VisualizarEmailPorUUID(id);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleEmail();
    }, [handleEmail]);

    const handleEmailCheck = useCallback(async () => {
      if(emailPorUUID.length > 0 ){
            setCheckedEmail(true);
        }else{
            setCheckedEmail(false);
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [emailPorUUID]);

    useEffect(() => {
        handleEmailCheck();
    }, [handleEmailCheck]);



    const handleTagLista = useCallback(async () => {
        if(tagPorUUID.length > 0 ){
            setTag(true);
        }else{
            setTag(false);
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [tagPorUUID]);

    useEffect(() => {
        handleTagLista();
    }, [handleTagLista]);

    

    const handleImagem = useCallback(async () => {
        await verImagemTarefa(id);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleImagem();
    }, [handleImagem]);

    const handleHistorico = useCallback(async () => {
        await visualizarHistoricoPorUUID(id);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleHistorico();
    }, [handleHistorico]);

    useEffect(() => {
        if(isLoadingEditID === false){
            handleListaTag();

          setCada(VerTarefaPorUUID[0]?.recorrente?.dia  || "");
            setFrequencia(String(VerTarefaPorUUID[0]?.recorrente?.tipo) || "");
            setDiaSemana(String(VerTarefaPorUUID[0]?.recorrente?.dias_semana) || []);
          setRecorrente(VerTarefaPorUUID[0]?.recorrente || null);
  
        }
        if(isLoadingUpload === false){
            setImagem(tarefaImagem[0] || '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingEditID, isLoadingUpload]);

  



    const handleClickOpenConfirmImagem = async (imagem) => {
        setOpenConfirmarImagem(true);
        setModalImagem(imagem);
    };

    const handleCloseConfirmImagem = async () => {
        setOpenConfirmarImagem(false);
        setModalImagem('');
    };

    const frequenciaTarefa = {
        1: "Semanal",
        2: "Mensal"
    }
    const diaDaSemana = ["Domingo","Segunda-Feira", "Terça-Feira","Quarta-Feira", "Quinta-Feira", "Sexta-Feira","Sábado"];


    function exibeDiasDaSemana() {
        return JSON.parse(dias_semana.replace(/"/g, '')).map(i =>  (
            <Typography key={i} value={i}>
                {diaDaSemana[i]}
            </Typography>
        ));
    }

  /*   ABRIR RECORRENTE */


    const [tab, setTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    }; 


    const [email_automatico, setCheckedEmail] = useState(false);

    const handleEmailAutomatico = (event) => {
        setCheckedEmail(event.target.checked);
    };


    /*   tag */
    const [nome_tag_modal, setNomeTagModal] = useState('');
    const [tipo_tag_modal, setTipoTagModal] = useState('');
    const [uuid_modal_tag, setUUIDModal] = useState('');
    const [itemTag, setItemTagModal] = useState([]);


    const [tag, setTag] = useState(false);

    //Verifica se abre a lista de tag ou não
    const handleTag = (event) => {
        setTag(event.target.checked);
    };

    //Verifica se abre modal pra editar tag 
    const [openConfirmEditaTag, setModalEditaTag] = useState(false);

    function handleAbreEditaModalTag(titulo, itens,uuid){
        let tipoEdita = itens.length === 0 ? '1' : '2';
        setTipoTagModal(tipoEdita);
        setNomeTagModal(titulo);
        setUUIDModal(uuid);
        setItemTagModal([itens]);
     
        setTimeout(() => {
            setModalEditaTag(true);
        }, '1000');
    }

    function handleCloseEditaModalTag(uuid){
        setModalEditaTag(false);
        setTipoTagModal('');
        setNomeTagModal('');
        setUUIDModal('');
        setItemTagModal([]);
    }

    //Qr code
    const [abreImagemModal, setModalImageModal] = useState(false);
    const [linkImagem, setLinkImagem] = useState(false);


    function handleAbreImagemModal(link){
        setModalImageModal(true);
        setLinkImagem(link);
    }

    function handleFechaImagemModal(){
        setModalImageModal(false);
        setLinkImagem('');
    }

    /*   ABRE modal histórico */
    const [abreModalHistoricoResposta, setAbreModalHistoricoResposta] = useState(false);
    const [acaoResposta, setAcaoResposta] = useState('');
    const [uuidHistorico, setUUIDHistorico] = useState('');
    const [textoHistorico, setTextoHistorico] = useState('');

    function handleAbreModalHistoricoResposta(uuid, acao, textoHistorico ){
        setAcaoResposta(acao);
        setUUIDHistorico(uuid);
        setTextoHistorico(textoHistorico);
        setAbreModalHistoricoResposta(true);
    }

    function handleFechaHistoricotoModalResposta(){
        setAbreModalHistoricoResposta(false);
    }

    // LISTA RESPOSTAS
    const handleResposta = useCallback(async () => {
        await VisualizarRespostaPorUUID(id);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [id]);

    useEffect(() => {
        handleResposta();
    }, [handleResposta]);

    const data = VerRespostaPorUUID?.cabecalho?.grafico?.respostas?.dados;
    function getKeyByValueId(object, value) { 
        return Object.keys(object).find(key =>  object[key].id === value);
    }

    let history = useHistory();

    const handleClickOpenEdit = async (e) => {
        e.preventDefault();
        history.push("/checklist/"+id);
    };

    return (
        <>
        <Box sx={{ width: '100%'}}>
        {analista === 2 && (administrador === 1 || gerente === 1 || gestor === 1 || master === 1) ?
            <S.EditarTarefa onClick={e => handleClickOpenEdit(e)}>
                Clique aqui para editar essa auditoria
            </S.EditarTarefa>
        : null}
        <Tabs value={tab} onChange={handleChangeTab} centered style={{ backgroundColor: 'white' }}>
            <Tab label="Auditoria" />
            <Tab label="Respostas" />
            <Tab label="Históricos" />
        </Tabs>
        {tab === 0 ?
            <S.Container>
                {isLoadingEditID === true  ? 
                    <Loading />
                :
                <>
                <S.ContainerCardForm>
                    <div className="card-form">
                        <Card className="item">
                            <CardContent>
                                <S.TituloTopo>Dados </S.TituloTopo>
                                <S.Column>
                                    <S.TituloGrande> <span>Cliente</span>: {VerTarefaPorUUID[0]?.cliente?.nome}</S.TituloGrande>  
                                    <S.TituloGrande> <span>Nome</span>: {VerTarefaPorUUID[0]?.acao}</S.TituloGrande>  
                                    <S.TituloGrande> <span>Descrição</span>: {VerTarefaPorUUID[0]?.problema}</S.TituloGrande>    
                                    <S.TituloGrande> <span>Responsável</span>: {VerTarefaPorUUID[0]?.responsavel?.nome}</S.TituloGrande>
                                    <S.TituloGrande> <span>Status</span>: { VerTarefaPorUUID[0]?.status_texto }
                                    </S.TituloGrande>       
                                </S.Column>
                            </CardContent>
                        </Card>
                        <Card className="item">
                            <CardContent>
                                <S.TituloTopo>Vincule um questionário</S.TituloTopo>
                                <S.Column>
                                    <S.TituloGrande> <span>Questionário</span>: {VerTarefaPorUUID[0]?.checklist?.titulo}</S.TituloGrande>   
                                    <S.TituloGrande> <span>Origem</span>: {origemLista[getKeyByValueId(origemLista,VerTarefaPorUUID[0].origem)]?.titulo || ''}</S.TituloGrande>   
                                </S.Column>
                                
                            </CardContent>
                        </Card>
                        <Card className="item">
                            <CardContent>
                                <S.TituloTopo>Datas</S.TituloTopo>
                                <S.Column>
                                    <S.Row>
                                        <S.TituloGrande> <span>Início previsto</span>: {VerTarefaPorUUID[0]?.inicio_previsto?.br}</S.TituloGrande> 
                                    </S.Row>
                                    <S.Row>
                                        <S.TituloGrande> <span>Conclusão prevista</span>: {VerTarefaPorUUID[0]?.conclusao_prevista?.br}</S.TituloGrande> 
                                    </S.Row>  
                                    <S.Row>
                                        <S.TituloGrande> <span>Início </span>: {VerTarefaPorUUID[0]?.inicio?.br}</S.TituloGrande> 
                                    </S.Row>  
                                    <S.Row>
                                        <S.TituloGrande> <span>Conclusão </span>: {VerTarefaPorUUID[0]?.conclusao?.br}</S.TituloGrande> 
                                    </S.Row>     
                                </S.Column>
                            </CardContent>
                        </Card>
                    </div>
                   <div className="card-form recorrencia">

                        <Card className="item">
                            {recorrente !== null ?
                                <>
                                <CardContent className="container_frequencia">
                                    <S.Column>
                                        <S.TituloTopo>Recorrência</S.TituloTopo>
                                        <InputLabel>Frequência (Tipo)</InputLabel>
                                        <Typography>{frequenciaTarefa[tipo]}</Typography>
                                        {tipo !== '' && tipo === '2' ?
                                        <>
                                        <InputLabel>Dia</InputLabel>
                                        <Typography>{dia || ''}</Typography>
                                        </>
                                        : tipo !== '' && tipo === '1' ?
                                        <>
                                        <InputLabel className="mt20 dia_semana">Dias da semana</InputLabel>
                                        <FormControl className="mt20">
                                            <Typography>{exibeDiasDaSemana()}</Typography>
                                        </FormControl>
                                        </>
                                        : null }
                                    </S.Column>
                                </CardContent>
                                
                                </>
                            : 
                            <CardContent className="container_frequencia">
                                <S.Column>
                                    <S.TituloTopo>Recorrência</S.TituloTopo>
                                </S.Column>
                                
                            </CardContent>
                            }
                        </Card>
                    </div>

                    <div className="card-form" style={{marginTop:'20px'}} >
                        <FormControl>
                            <FormControlLabel
                            control={
                                <Switch
                                    checked={tag}
                                    onChange={handleTag}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                                label="Tag?"
                            />
                        </FormControl>
                    </div>
                    {tag === true ?
                        <S.Tag>
                            <section className="titulo">
                                <h1>Tags</h1>
                            </section>
                            <section className="container">
                                {tagPorUUID && tagPorUUID.map(lista =>  (
                                    <div className="tag" key={lista.uuid}>
                                        <p>{lista.titulo}</p>
                                        <div className="icon">
                                            <EditIcon style={{cursor: 'pointer'}} onClick={() => handleAbreEditaModalTag(lista.titulo,lista.itens, lista.uuid) } />
                                        </div>
                                    </div>
                                ))}
                            </section>
                        </S.Tag>
                    : null}
                    <div className="card-form" style={{marginTop:'20px'}} >

                        <S.Tag>
                            <section className="titulo">
                                <h1>QrCodes</h1>
                            </section>
                            {tagPorUUID && tagPorUUID.map(lista =>  (
                                <section className="container" key={lista.uuid}>
                                    {lista.itens && lista.itens.map(lista =>  (
                                        <div className="tag" key={lista.uuid}>
                                            <p>{lista.nome}</p>
                                            <div className="icon">
                                                <PrintIcon style={{cursor: 'pointer'}} onClick={() => handleAbreImagemModal(lista.qrcode) } />
                                            </div>
                                        </div>
                                    ))}
                                </section>
                            ))}
                        </S.Tag>
                    </div>

                    <div className="card-form" style={{marginTop:'20px'}}>
                        <FormControl>

                            <FormControlLabel
                            control={
                                
                                <Switch
                                    checked={email_automatico}
                                    onChange={handleEmailAutomatico}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                                label="E-mail automático?"
                            />
                        </FormControl>
                     </div>
                    {email_automatico === true ?
                    <div className="card-form recorrencia">
                        <Card className="item">
                            <>
                            <CardContent className="container_frequencia">
                                <S.Column>
                                    <S.TituloGrande> <span>Destinatários</span>: {emailPorUUID[0]?.destinatario}</S.TituloGrande>  
                                    <S.TituloGrande> <span>Assunto</span>: {emailPorUUID[0]?.assunto}</S.TituloGrande> 
                                    <S.TituloGrande> <span>Corpo Email</span>: {emailPorUUID[0]?.corpo_email}</S.TituloGrande> 
                                </S.Column>
                            </CardContent>
                            </>
                        </Card>
                    </div> 
                    : null }
                    <S.ContainerImage>
                        <p>Arquivos enviados</p>
                        <Grid container className="imagem_enviada" spacing={2}>
                            {imagem.length > 0 ? 
                                imagem.map((lista, index) =>  (
                                    <Grid  key={index} item xs={4}>
                                        <Paper>
                                            <p>{lista.imagem}</p>
                                            <IconButton edge="end" target="blank" href={lista.imagem} aria-label="download">
                                                <CloudDownload />
                                            </IconButton>
                                        </Paper>
                                    </Grid>
                                ))
                                : 'Sem arquivos enviados'}
                        </Grid>
                    </S.ContainerImage>
                    
                </S.ContainerCardForm>
                </>
                }
            </S.Container>
        : tab === 1 ?
            <S.ContainerCardForm>
                <S.ContainerResposta>
                    <h2>Respostas (usuários)</h2>
                    {VerRespostaPorUUID?.cabecalho?.responsavel?.nome ? 
                    <ReactToPrint
                        trigger={() => <button className="imprimir">Imprimir</button>}
                        content={() => componentRef.current}
                    />
                    : null}
                    <div id="listaResposta" ref={el => (componentRef.current = el)}>
                    
                   {VerRespostaPorUUID?.cabecalho?.responsavel?.nome ? 
                    <S.Column style={{marginBottom:'20px'}}>
                        <div className="cabecalho">
                            <div className="cabecalhoLeft">
                                <div className="titulo"> 
                                    <span>Responsável:</span>
                                    <p>Nome: {VerRespostaPorUUID?.cabecalho?.responsavel?.nome}</p>
                                    <p>Email: {VerRespostaPorUUID?.cabecalho?.responsavel?.email}</p>
                                </div>
                                <div className="titulo"> 
                                    <span>Solicitante:</span>
                                    <p>Nome: {VerRespostaPorUUID?.cabecalho?.solicitante?.nome} </p>
                                </div>
                                <div className="titulo"> 
                                    <span>Checklist:</span> 
                                    <p>Título: {VerRespostaPorUUID?.cabecalho?.tarefa?.acao}</p>
                                    <p>Descrição: {VerRespostaPorUUID?.cabecalho?.tarefa?.problema}</p>
                                    <p>Início previsto: {VerRespostaPorUUID?.cabecalho?.tarefa?.inicio_previsto}</p>
                                </div>
                                {/* <div className="titulo"> 
                                    <span>Peso:</span> 
                                    <p >{VerRespostaPorUUID?.cabecalho?.peso} </p>
                                </div> */}
                                <div className="nota"> 
                                    <span>Nota:</span> 
                                    <p style={{background: VerRespostaPorUUID?.cabecalho?.nota?.cor}}>{VerRespostaPorUUID?.cabecalho?.nota?.titulo} </p>
                                </div>
                                <div className="titulo"> 
                                    <span>Problema:</span> 
                                    <p>{VerRespostaPorUUID?.cabecalho?.tarefa?.problema} </p>
                                </div>
                                <div className="titulo"> 
                                    <span>Início:</span> 
                                    <p>{VerRespostaPorUUID?.cabecalho?.tarefa?.inicio}</p> 
                                </div>
                                <div className="titulo"> 
                                    <span>Conclusão:</span> 
                                    <p>{VerRespostaPorUUID?.cabecalho?.tarefa?.conclusao} </p>
                                </div>
                            </div>                            
                            <div className="cabecalhoRight">
                                <header>
                                    <figure>
                                        <img src={logo} alt="logo"/>
                                    </figure>
                                </header>
                                <div className="cabecalhoRespostas">
                                    <table>
                                        <tr>
                                            <td className="title">Resposta</td>
                                            <td className="title">%</td>
                                            <td className="title"></td>
                                        </tr>
                                        {VerRespostaPorUUID?.cabecalho?.grafico?.respostas?.dados && VerRespostaPorUUID?.cabecalho?.grafico?.respostas?.dados.map((item, index) => (
                                            <tr>
                                                <td>{item.name}</td>
                                                <td>{((item.value / VerRespostaPorUUID?.cabecalho?.grafico?.respostas?.total) * 100).toFixed(0)}%</td>
                                                <td className="cor" style={{backgroundColor: COLORS[index]}}></td>
                                            </tr>
                                        ))}
                                    </table>
                                    
                                    {data ?
                                    <PieChart width={340} height={340}>
                                        <Pie
                                            data={data}
                                            cx={120}
                                            cy={140}
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={120}
                                            fill="#8884d8"
                                            dataKey="value"
                                            nameKey="name"
                                        >
                                            {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </S.Column>
                    : null}
                    {VerRespostaPorUUID?.respostas && Object.keys(VerRespostaPorUUID.respostas).map((key, index) => (

                    <Accordion key={index} >
                        <AccordionSummary
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        >
                        <S.Column>
                        <S.TituloGrande className="grupo"><span>{key}</span></S.TituloGrande>
                            {key && VerRespostaPorUUID.respostas[key].map((lista, index) => (
                            <div className={index}>
                            <S.TituloGrande> <span>Data da resposta</span>: {lista?.data_criacao?.br}</S.TituloGrande> 
                            <S.TituloGrande> 
                                <span>Peso: {lista?.pergunta?.peso}</span> 
                            </S.TituloGrande>
                            <S.Respostas> 
                                <span>Pergunta</span> 
                                <p>{lista?.pergunta?.texto}</p>
                            </S.Respostas>
                            <S.Respostas> 
                                <span>Resposta:</span> 
                                <p>{lista?.resposta}</p>
                            </S.Respostas>
                            <S.ContainerRespostaImagem>
                                <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164} >
                                    {lista.imagens && lista.imagens.map((item) => (
                                        <ImageListItem key={item.uuid}>
                                        <img
                                            src={`${item.imagem}?w=164&h=164&fit=crop&auto=format`}
                                            srcSet={`${item.imagem}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            alt={lista?.usuario?.nome }
                                            loading="lazy"
                                            onClick={() => handleClickOpenConfirmImagem(item.imagem)}
                                        />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </S.ContainerRespostaImagem>
                            
                            <S.Comentario>
                                <S.TituloGrande style={{marginBottom: "10px"}}> <span>Comentário</span>: </S.TituloGrande>
                                {lista?.historico?.usuario?.nome ?
                                <div className="conteudo">
                                    <p className="nome"> <Avatar alt={lista?.historico?.usuario?.nome} src={lista?.historico?.usuario?.avatar} /> {lista?.historico?.usuario?.nome} <span>{lista?.historico?.data_atualizacao?.br}</span> </p>
                                    <p className="historico">{lista?.historico?.texto}</p>
                                </div> 
                                : null}
                                {!lista?.historico?.texto ?
                                <button onClick={() => handleAbreModalHistoricoResposta(lista?.uuid,'add', '')}>
                                    Comente
                                </button>
                                :
                                <button onClick={() => handleAbreModalHistoricoResposta(lista?.historico?.uuid,'edit', lista?.historico?.texto)}>
                                    Comente
                                </button>
                                }
                            </S.Comentario>
                            </div>
                            ))}
                        </S.Column>
                        </AccordionSummary>
                    </Accordion>
                    ))}
                    </div>
                </S.ContainerResposta>
            </S.ContainerCardForm>
        : tab === 2 ?
            <S.Historico>
                <div className="card_historico">
                {historicoPorUUID ? 
                <List dense={true} style={{width: '90%'}}>
                {historicoPorUUID && historicoPorUUID.map(lista => (
                        <>
                        <ListItem alignItems="flex-start" key={lista.uuid}>
                            <ListItemAvatar>
                            <Avatar alt={lista.usuario.nome} src={lista.usuario.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                            primary={lista.texto}
                            secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                {" por "+lista.usuario.nome}
                                </Typography>
                                </React.Fragment>
                            }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </>
                    ))}
                </List>
                : 
                    <div className="zero">
                        <p>Sem históricos no momento</p>
                    </div>
                }
                </div>
            </S.Historico>
        : null}
        </Box>
           <Dialog
                open={openConfirmImagem}
                onClose={handleCloseConfirmImagem}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                fullScreen={true}
                maxWidth="md"
                TransitionComponent={Transition}
            >
                <DialogTitle id="id">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} ></Box>
                        <Box>
                            <IconButton onClick={handleCloseConfirmImagem}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent className="imagem_modal">
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <img src={imagemModal} alt="imagem" height="80%" />
                    </Box>
                </DialogContent>
            </Dialog>
    
            {openConfirmEditaTag ?
                <EditaTagModal 
                    abre={openConfirmEditaTag} 
                    fecha={handleCloseEditaModalTag} 
                    uuidAuditoria={VerTarefaPorUUID[0].uuid} 
                    itens={itemTag} 
                    nomeTag={nome_tag_modal}
                    tipoTag={tipo_tag_modal}
                    uuidTagModal={uuid_modal_tag}
                    acao={'visualiza'}
                />
            : null}
  
            {abreModalHistoricoResposta ? 
                <HistoricoComponent
                    vinculo={uuidHistorico}
                    tabela="respostas"
                    fecha={handleFechaHistoricotoModalResposta} 
                    abre={abreModalHistoricoResposta}
                    acao={acaoResposta}
                    uuid={id}
                    textoEdit={textoHistorico}
                /> 
            : null }
            {abreImagemModal ? 
                <ImagemModal abre={abreImagemModal} fecha={handleFechaImagemModal} link={linkImagem} />
            : null}
        </>
    );
}






