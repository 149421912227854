import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback
} from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import 'moment/locale/pt-br';

moment.locale('pt-br');

const AcaoContext = createContext({});

export const AcaoProvider = (props) => {
  const [acaoLista, setAcaoLista] = useState([]);
  const [acaoPorUUID, setAcaoPorUUID] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [activePage, setPageActive] = useState('');
  const [pagination, setPagination] = useState([] || '');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [VerAcaoPorUUID, setVerAcaoPorUUID] = useState([]);
  const [VerAcaoPorCliente, setVerAcaoPorCliente] = useState([]);
  const [isLoadingEditID, setIsLoadingEditID] = useState(true);

  const handleAcao = useCallback(async () => {
    const buscaAtiva = localStorage.getItem('salvarBuscaAcao');
    try{
      setIsLoading(true);
      const response = await api.get('/acao');

      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setPagination(data);
      setAcaoLista(response.data.itens);
      setIsLoading(false);

    }catch(err){
      setIsLoading(false);
    }
  }, []);

  // Paginação
  async function acaoPage(page) {
    try {
      setIsLoading(true);

      const response = await api.get('/acao?page=' + page);

      setAcaoLista(response.data.itens);
      const data = [
        {
          pagina_atual: response.data.pagina_atual,
          proxima_pagina: response.data.proxima_pagina,
          pagina_anterior: response.data.pagina_anterior,
          ultima_pagina: response.data.ultima_pagina,
          total: response.data.total
        }
      ];
      setIsLoading(false);
      setPagination(data);
    } catch (err) {
      setIsLoading(false);
    }
  }

  // USUÁRIOS POR UUID

  async function getAcaoPorUuid(uuid) {
    try {
      setIsLoading(true);
      const response = await api.get('/acao/' + uuid);

      setAcaoPorUUID(response.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function VisualizarAcaoPorUUID(uuid) {
    try {
      setIsLoading(true);
      const responseedit = await api.get('/acao/' + uuid);

      setVerAcaoPorUUID([responseedit.data]);
      setIsLoading(false);
      return {
        erro: false
      };
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response?.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: true
      };
    }
  }

  async function VisualizarAcaoPorCliente(uuid) {
    try {
      setIsLoading(true);

      let uuidSolicitante = String(localStorage.getItem('LPROWORK'));

      const master = Number(localStorage.getItem('MMPROWORK'));
      const administrador = Number(localStorage.getItem('MPROWORK'));
      const gerente = Number(localStorage.getItem('GTPROWORK'));
      const gestor = Number(localStorage.getItem('GSPROWORK'));
      const analista = Number(localStorage.getItem('ANPROWORK'));

      if (
        (gerente === 1 || gestor === 1) &&
        administrador === 2 &&
        analista === 2 &&
        master === 2
      ) {
        let responseedit = await api.get(
          '/acao?cliente=' + uuid + '&solicitante=' + uuidSolicitante
        );
        if (responseedit.data.itens.length > 0) {
          setVerAcaoPorCliente([responseedit.data.itens]);
          const data = [
            {
              pagina_atual: responseedit.data.pagina_atual,
              proxima_pagina: responseedit.data.proxima_pagina,
              pagina_anterior: responseedit.data.pagina_anterior,
              ultima_pagina: responseedit.data.ultima_pagina,
              total: responseedit.data.total
            }
          ];
          setIsLoading(false);
          return {
            erro: false
          };
        }
      } else {
        let responseedit = await api.get('/acao?cliente=' + uuid);
        if (responseedit.data.itens.length > 0) {
          setVerAcaoPorCliente([responseedit.data.itens]);
          const data = [
            {
              pagina_atual: responseedit.data.pagina_atual,
              proxima_pagina: responseedit.data.proxima_pagina,
              pagina_anterior: responseedit.data.pagina_anterior,
              ultima_pagina: responseedit.data.ultima_pagina,
              total: responseedit.data.total
            }
          ];
          setIsLoading(false);
          return {
            erro: false
          };
        }
      }
    } catch (err) {
      setIsLoading(false);
      if (err) {
        if (err.response?.data?.message !== 'Nenhuma acao encontrada!') {
          toast.error(err.response?.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
      return {
        erro: true
      };
    }
  }

  // Criar
  async function createAcao(request) {
    try {
      setIsLoading(true);

      const response = await api.post('/acao', request);

      setIsLoading(false);

      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER
      });      

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: true
      };
    }
  }

  // EDITANDO
  async function editAcao(request, uuid) {
    try {
      setIsLoading(true);

      const response = await api.post('/acao/' + uuid, request);
      setIsLoading(false);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: false
      };
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER
      });
      return {
        erro: true
      };
    }
  }

  // Pesquisar Catálogo

  async function searchAcao(search) {
    try {
      setIsLoading(true);

      let url = "";

      if(search.status){
        url += "?status=" + search.status;
      }else{
        url += "?status=";
      }
      if(search.acao){
        url += "&acao=" + search.acao;
      }
      if(search.idAcao){
        url += "&id=" + search.idAcao;
      }
      if(search.problema){
        url += "&problema=" + search.problema;
      }
      if(search.responsavel){
        url += "&responsavel=" + search.responsavel;
      }
      if(search.cliente){
        url += "&cliente=" + search.cliente;
      }
      if(search.inicioPrevistoInico){
        url += "&inicio_previsto_inicio=" + search.inicioPrevistoInico;
      }
      if(search.inicioPrevistoFinal){
        url += "&inicio_previsto_final=" + search.inicioPrevistoFinal;
      }
      if(search.conclusaoPrevistaInicio){
        url += "&conclusao_prevista_inicio=" + search.conclusaoPrevistaInicio;
      }
      if(search.conclusaoPrevistaFinal){
        url += "&conclusao_prevista_final=" + search.conclusaoPrevistaFinal;
      }
      if(search.inicio){
        url += "&inicio=" + search.inicio;
      }
      if(search.conclusao){
        url += "&conclusao=" + search.conclusao;
      }
      if(search.origem){
        url += "&origem=" + search.origem;
      }
      if(search.pasta){
        url += "&cliente=" + search.pasta;
      }
      if(search.codigo){
        url += "&auditoria=" + search.codigo;
      }
      const response = await api.get('acao'+url);

      if (response.data.error === true) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      } else {
        localStorage.setItem('buscaAtivaAcao', 'ativa');
        localStorage.setItem('salvarBuscaAcao', url);

        setAcaoLista(response.data.itens);
        const data = [
          {
            quantidade_pagina: response.data.quantidade_pagina,
            pagina_atual: response.data.pagina_atual,
            proxima_pagina: response.data.proxima_pagina,
            pagina_anterior: response.data.pagina_anterior,
            ultima_pagina: response.data.ultima_pagina,
            total: response.data.total
          }
        ];
        setPagination(data);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setAcaoLista([]);
      // toast.error(err.response.data.message, {
      //   position: toast.POSITION.TOP_CENTER
      // });
    }
  }

  async function downloadRelatorioAcao() {
    try {
        setIsLoading(true);
        const busca = localStorage.getItem('salvarBuscaAcao');

        let urlRelatorio = busca ? busca : '';

        let response = await api.get('/acao/relatorio' + urlRelatorio, {responseType: 'blob'});   
        var date = new Date();
        var formattedDate = moment(date).format('YYYYMMDD');
        let filename = 'relatorio_acao_'+formattedDate;
    
        const url = window.URL.createObjectURL(new Blob([response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        setIsLoading(false);      

    } catch (err) {
        setIsLoading(true);   
        toast.error('Erro!', {
            position: toast.POSITION.TOP_CENTER
        });
        setIsLoading(false); 
    }
  }

  async function limpaAcao() {
    try {
      setIsLoading(false);

      handleAcao();
      let keysToRemove = ['buscaAtivaAcao', 'salvarBuscaAcao'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      window.location.reload();

      setIsLoadingSearch(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function deletarAcao(uuid) {
    try {
      setIsLoading(true);
      let response = await api.delete('/acao/' + uuid);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      setIsLoading(false);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      setIsLoading(false);
      toast.success(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });

      return {
        erro: true
      };
    }
  }

  return (
    <AcaoContext.Provider
      value={{
        isLoading,
        acaoLista,
        getAcaoPorUuid,
        acaoPorUUID,
        editAcao,
        createAcao,
        acaoPage,
        pagination,
        activePage,
        isLoadingEdit,
        searchAcao,
        limpaAcao,
        isLoadingSearch,
        VerAcaoPorUUID,
        VisualizarAcaoPorUUID,
        deletarAcao,
        isLoadingEditID,
        VisualizarAcaoPorCliente,
        VerAcaoPorCliente,
        handleAcao,
        downloadRelatorioAcao
      }}
    >
      {props.children}
    </AcaoContext.Provider>
  );
};

export function useAcao() {
  const context = useContext(AcaoContext);

  return context;
}
