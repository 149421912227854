import React, { useState } from 'react';

import logo from '../assets/logo.png';
import { userNovaSenha } from '../hooks/auth';
import { InputAdornment, IconButton, Typography, TextField, CssBaseline , Button, makeStyles, Container, CircularProgress } from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ErrorMessage from '../components/ErrorMessage';
import Alert from '@material-ui/lab/Alert';
import {Box} from '@material-ui/core';

import {
    useParams
  } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  link: {
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  formSenha: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



export default function NovaSenha() {

  const [nova_senha, setNovaSenha] = useState('');
  const [confirmar_senha, setConfirmarSenha] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mostraSenha, setMostraSenha] = useState(false);
  const handleClickMostraSenha = () => setMostraSenha(!mostraSenha);
  const handleMouseDownPassword = () => setMostraSenha(!mostraSenha);
  const [sucesso, setSucesso] = useState('');
  const { token } = useParams();
  
  const classes = useStyles();

  const handleSubmitNovaSenha = async event => {
    event.preventDefault();
    try {
    setIsLoading(true);
      let result = await userNovaSenha({nova_senha,confirmar_senha, token});
      if(result){
        setNovaSenha(" ");
        setConfirmarSenha(" ");
        setError("");
        setSucesso(result);
      }
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setSucesso("");
      setIsLoading(false);
    }
  };


  const handleLogin = async () => {
    window.location.pathname = '/login';
  };

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="Logo" style={{marginBottom: "20px"}} />
        <Typography component="h1" variant="h5">
            Nova senha
        </Typography>
        <form className={classes.formSenha} onSubmit={handleSubmitNovaSenha} noValidate>
          {error && <ErrorMessage message={error} />}
            {sucesso && 
            <Box my={4}>
                <Alert severity="success">{sucesso}</Alert>
            </Box>
            }
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="nova_senha"
              label="Nova senha"
              name="nova_senha"
              autoFocus
              value={nova_senha}
              type={mostraSenha ? "text" : "password"}
              onChange={event => setNovaSenha(event.currentTarget.value)}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickMostraSenha}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {mostraSenha ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmar_senha"
              label="Confirmar senha"
              value={confirmar_senha}
              type={mostraSenha ? "text" : "password"}
              id="confirmar_senha"
              onChange={event => setConfirmarSenha(event.currentTarget.value)}
            />
          {sucesso ? 
          <div style={{cursor: "pointer", marginTop: '20px', textAlign: 'center'}} onClick={() => handleLogin()}>
              <Typography variant="subtitle1" gutterBottom className={classes.link} >
                Fazer login
              </Typography>
          </div>
          : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isLoading ? (
              <CircularProgress
                isIndeterminate
                size="24px"
                color="inherit"
              />
            ) : (
              'Alterar senha'
            )}
          </Button>
        </form>
      </div>
    </Container>
  );
}