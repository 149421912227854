import * as S from "./styles";
import { useState, useEffect }  from 'react';
import { useCheckList } from "../../hooks/checklist"
import CloseIcon from "@material-ui/icons/Close";
import { TextField, FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, DialogActions, ButtonGroup, Button, IconButton, Grid, Switch, FormControlLabel } from "@material-ui/core";
import api from "../../services/api";
import { toast } from 'react-toastify';


export function EditNovoItemCheckListModal({ abre, fecha, uuid, checklist, grupo, itens, setUpdatePage }) {
    const { editItemCheckList } = useCheckList();

    const [texto, setTitulo] = useState("");
    const [status, setStatus] = useState("");
    const [tipo, setTipo] = useState("");
    const [peso, setPeso] = useState(1);
    const [parametro, setParametro] = useState("");
    const [loading, setLoading] = useState(false);
    
    const handleEditChecklist = async event => {
        event.preventDefault();
        
        let data = {
            texto,
            status,
            tipo,
            peso,
            foto_obrigatoria: foto_obrigatoria === true ? 1 : 2,
            parametro,
            ordem: 1,
            checklist
        }
       

        await editItemCheckList(data, uuid, fecha, grupo);
        setUpdatePage(true);
 
    }  

    useEffect(() => {
        setLoading(true);
        api.get("/item/"+uuid)
            .then(({ data }) => {
                setLoading(false);
                setTitulo(data.texto || "");
                setTipo(data.tipo || "");
                setStatus(data?.status || "");
                setParametro(data?.parametro || "");
                let foto_atual = data?.foto_obrigatoria === 1 ? true : false;
                setFotoObrigatoria(foto_atual || false);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response?.data.message, {
                    position: toast.POSITION.TOP_CENTER
                }); 
    
            })
            .finally((response) => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [foto_obrigatoria, setFotoObrigatoria] = useState(false);

    const handleFotoObrigatoria = (event) => {
        setFotoObrigatoria(event.target.checked);
    };

  
    return (

        <Dialog open={abre} onClose={fecha} className="modal_dialog">
            <S.Container onSubmit={handleEditChecklist}>
                <DialogTitle className="titulo">
                    Editar um item
                    <IconButton className="fecha" onClick={fecha}>
                    <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent  className="grid_container">
                    <Grid container>
                        <Grid item xs={12}>
                            <S.Column>
                                <FormControl className="form_control">
                                    <TextField
                                    variant="outlined"
                                    label={'Questão'}
                                    value={loading || texto}
                                    placeholder="Questão"
                                    onChange={event => setTitulo(event.target.value)}
                                    />
                                </FormControl>
                                <FormControl className="form_control">
                                    <TextField
                                    variant="outlined"
                                    label={'Parâmetro'}
                                    value={loading || parametro}
                                    placeholder="Parâmetro"
                                    onChange={event => setParametro(event.target.value)}
                                    />
                                </FormControl>
                                <FormControl className="form_control" variant="outlined" >
                                    <InputLabel id="demo-simple-select-required-label">Tipo</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={event => setTipo(event.target.value)}
                                        value={tipo}
                                        label={'Tipo'}
                                        placeholder="Tipo"
                                    >
                                        <MenuItem value="">
                                            <em>Selecione um tipo</em>
                                        </MenuItem>
                                        <MenuItem value="9999">
                                            <em>Texto</em>
                                        </MenuItem>
                                        {itens.map(item => <MenuItem key={item.uuid} value={item.id}>{item.respostas}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                
                                <FormControl>
                                    <FormControlLabel
                                    control={
                                        <Switch
                                            checked={foto_obrigatoria}
                                            onChange={handleFotoObrigatoria}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                        label="Foto obrigatória?"
                                    />
                                </FormControl>
                                
                                <FormControl className="form_control" variant="outlined" >
                                    <InputLabel id="demo-simple-select-required-label">Status</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={event => setStatus(event.target.value)}
                                        value={loading || status}
                                        label={'Status'}
                                        placeholder="Status"
                                    >
                                        <MenuItem value="">
                                            <em>Selecione um status</em>
                                        </MenuItem>
                                        <MenuItem value={1}>Ativo</MenuItem>
                                        <MenuItem value={2}>Inativo</MenuItem>
                                    </Select>
                                </FormControl>
                            </S.Column>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className="container_acao">
                    <ButtonGroup
                    orientation="horizontal"
                    color="primary"
                    className="buttonGroup"
                    aria-label="vertical outlined primary button group"
                    >
                    <Button
                        className="botao edit"
                        variant="contained"
                        type="submit"
                    >
                        Atualizar
                    </Button>
                    </ButtonGroup>
                </DialogActions>
            </S.Container>
        </Dialog>
    )
}