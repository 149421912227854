import React, { useEffect, useState } from 'react';
import { useAcao } from '../../hooks/acao';
import { useAuditoria } from '../../hooks/auditoria';
import { useHistorico } from '../../hooks/historico';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  IconButton,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import Pagination from '@material-ui/lab/Pagination';
import Draggable from 'react-draggable';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Delete from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { Loading } from '../../components/Loading';
import { HistoricoComponent } from '../../components/HistoricoComponent';
import { SearchAcao } from './SearchAcao';

import * as S from './styles';

moment.locale('pt-br');

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function AcaoLista() {
  let history = useHistory();
  const {
    acaoLista,
    isLoading,
    handleAcao,
    pagination,
    acaoPage,
    deletarAcao,
    editAcao,
    downloadRelatorioAcao
  } = useAcao();
  const { uploadArquivo, deletarArquivo } = useAuditoria();
  const { historico, visualizarListaHistorico, isLoadingHistorico } =
    useHistorico();

  /**
   * State
   */
  const [acaoRender, setAcaoRender] = useState(false);
  const [uuid, setUuid] = useState(false);
  const [openModalDelete, setOpenModelDelete] = useState(false);
  const [openModalTask, setOpenModalTask] = useState(false);
  const [task, setTask] = useState(true);
  const [abreModalHistorico, setAbreModalHistorico] = useState(false);
  const [btnClose, setBtnClose] = useState(false);
  const [busca, setBusca] = useState(false);

  /**
   * Handles
   */

  const handleSelect = async (event, value) => {
    await acaoPage(value);
  };

  const handleDeletaConfirm = async () => {
    await deletarAcao(uuid);
    setAcaoRender(true);
    setOpenModelDelete(false);
  };

  const handleUpdateStatus = async (status, tipo = false) => {
    let data = {
      status,
      responsavel: task?.responsavel?.uuid,
      acao: task?.acao,
      origem: task?.origem,
      inicio_previsto: task?.inicio_previsto?.br,
      conclusao_prevista: task?.conclusao_prevista?.br,
      inicio: tipo === 'inicio' ? moment().format('DD/MM/yyyy HH:mm:ss') : null,
      conclusao:
        tipo === 'conclusao' ? moment().format('DD/MM/yyyy HH:mm:ss') : null
    };

    await editAcao(data, task.uuid);
    setOpenModalTask(false);
    setAcaoRender(!acaoRender);
  };

  const handleImages = async (files) => {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let data = {
          vinculo: task?.uuid,
          imagem: files[i],
          tabela: 'acoes'
        };
        await uploadArquivo(data);
        setTimeout(() => setAcaoRender(!acaoRender), 1000);
      }
    }
  };

  const handleHistorico = async (uuid) => {
    await visualizarListaHistorico(uuid, 'acoes');
  };

  const handleDeletaImagem = async (imagem) => {
    await deletarArquivo(imagem, false);
    setTimeout(() => setAcaoRender(!acaoRender), 1000);
  };

  const handleDownloadRelatorio = async () => {
    await downloadRelatorioAcao();
  };

  useEffect(() => {
    handleAcao();
  }, [acaoRender]);

  useEffect(() => {
    setTimeout(
      () => setTask({ ...acaoLista[task?.index], index: task?.index }),
      1000
    );
  }, [acaoLista]);

  return (
    <S.ContainerChecklist>
      <button type="button" onClick={() => history.push('/tarefa/nova')}>
        Nova tarefa
      </button>
      <button type="button" className="busca" onClick={() => setBusca(true)}>
        Buscar tarefa
      </button>
      <button
        className="buttonRelatorio"
        type="button"
        onClick={() => handleDownloadRelatorio()}
      >
        <span>Download relatório</span>
      </button>

      <div className="totalItens">
        <h1>Total de registros: {pagination[0]?.total}</h1>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Ação</th>
            <th>Problema</th>
            <th>Responsável</th>
            <th>Unidade</th>
            <th>Status</th>
            <th className="acao">Ações</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <Loading />
          ) : acaoLista.length > 0 ? (
            acaoLista.map((item, index) => (
              <tr key={item.uuid}>
                <td
                  onClick={() => {
                    setTask({ ...item, index });
                    handleHistorico(item.uuid);
                    setOpenModalTask(true);
                  }}
                >
                  {item.id}
                </td>
                <td
                  onClick={() => {
                    setTask({ ...item, index });
                    handleHistorico(item.uuid);
                    setOpenModalTask(true);
                  }}
                >
                  {item?.acao}
                </td>
                <td
                  onClick={() => {
                    setTask({ ...item, index });
                    handleHistorico(item.uuid);
                    setOpenModalTask(true);
                  }}
                >
                  {item?.problema}
                </td>
                <td
                  onClick={() => {
                    setTask({ ...item, index });
                    handleHistorico(item.uuid);
                    setOpenModalTask(true);
                  }}
                >
                  {item?.responsavel?.nome}
                </td>
                <td
                  onClick={() => {
                    setTask({ ...item, index });
                    handleHistorico(item.uuid);
                    setOpenModalTask(true);
                  }}
                >
                  {item.empresa_nome}
                </td>
                <td onClick={() => {}}>{item.status_texto}</td>
                <td
                  className="deletar"
                  onClick={() => {
                    setUuid(item.uuid);
                    setOpenModelDelete(true);
                  }}
                >
                  <p>Deletar</p>
                </td>
              </tr>
            ))
          ) : null}
        </tbody>
      </table>
      <S.ContainerPagination>
        {pagination.length ? (
          <Pagination
            showLastButton={true}
            showFirstButton={true}
            color="primary"
            count={pagination[0].ultima_pagina}
            page={Number(pagination[0].pagina_atual)}
            onChange={handleSelect}
          />
        ) : null}
      </S.ContainerPagination>

      <Dialog
        open={openModalDelete}
        onClose={() => setOpenModelDelete(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="modal_dialog"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Atenção!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja deletar essa tarefa?
            <br />
            Essa ação não poderá ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModelDelete(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleDeletaConfirm()} color="secondary">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalTask}
        onClose={() => setOpenModalTask(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="modal_dialog"
      >
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <DialogTitle
            style={{
              cursor: 'move',
              justifyContent: 'space-between',
              display: 'flex'
            }}
            id="draggable-dialog-title"
          >
            {task?.acao}
          </DialogTitle>
          <Button onClick={() => setOpenModalTask(false)} color="secondary">
            X
          </Button>
        </DialogActions>
        <DialogContent>
          <DialogContentText>
            <b>ID:</b> {task?.id}
            <br />
            <b>Descrição:</b> {task?.acao}
            <br />
            <b>Responsável:</b> {task?.responsavel?.nome}
            <br />
            <b>Solicitante:</b> {task?.solicitante?.nome}
            <br />
            <b>Origem:</b> {task?.origem_titulo}
            <br />
            <b>Cadastro:</b> {task?.data_criacao?.br}
            <br />
            <b>Início previsto:</b> {task?.inicio_previsto?.br}
            <br />
            <b>Conclusão prevista:</b> {task?.conclusao_prevista?.br}
            <br />
            <b>Pasta:</b> {task?.cliente_nome}
            <br />
            <b>Status:</b> {task?.status_texto}
            <br />
            <b>Início:</b> {task?.inicio?.br}
            <br />
            <b>Conclusão:</b> {task?.conclusao?.br}
          </DialogContentText>

          <S.ContainerImage>
            {task?.status !== 5 && (
              <>
                <h2>Anexar arquivo</h2>
                <Grid container className="drop-container">
                  <Grid item xs={12}>
                    <div>
                      <DropzoneArea
                        showFileNames={false}
                        showPreviews={false}
                        showPreviewsInDropzone={false}
                        acceptedFiles={[
                          'image/jpeg,image/jpg,image/png,image/gif',
                          'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.odt'
                        ]}
                        onChange={handleImages}
                        dropzoneText="Clique e selecione ou arraste arquivos para realizar upload"
                        filesLimit={1}
                        maxFileSize={5120000} // 5120 KB
                      />
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
            <p>Arquivos enviados</p>
            <Grid container className="imagem_enviada" spacing={2}>
              {task?.imagem?.length > 0
                ? task?.imagem.map((lista, index) => (
                    <Grid key={index} item xs={4}>
                      <Paper>
                        <p>{lista.imagem}</p>
                        <IconButton
                          edge="end"
                          target="blank"
                          href={lista.imagem}
                          aria-label="download"
                        >
                          <CloudDownload />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeletaImagem(lista.uuid)}
                          edge="end"
                          aria-label="delete"
                        >
                          <Delete />
                        </IconButton>
                      </Paper>
                    </Grid>
                  ))
                : 'Sem arquivos enviados'}
            </Grid>
          </S.ContainerImage>
          <S.Historico>
            {isLoadingHistorico === true ? (
              <Loading />
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => setAbreModalHistorico(true)}
                >
                  Novo histórico
                </button>
                <div className="card_historico">
                  {historico && historico.length > 0 ? (
                    <List dense={true} style={{ width: '90%' }}>
                      {historico &&
                        historico.map((lista) => (
                          <div key={lista.uuid}>
                            <ListItem alignItems="flex-start" key={lista.uuid}>
                              <ListItemAvatar>
                                <Avatar
                                  alt={lista.usuario.nome}
                                  src={lista.usuario.avatar}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={lista.texto}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                    >
                                      {' por ' + lista.usuario.nome}
                                    </Typography>
                                    <br />
                                    <Typography
                                      sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                    >
                                      {lista.data_criacao.br}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                          </div>
                        ))}
                    </List>
                  ) : (
                    <div className="zero">
                      <p>Sem históricos no momento</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </S.Historico>
        </DialogContent>
        <DialogActions>
          {task?.status === 1 && (
            <Button onClick={() => handleUpdateStatus(2)} color="primary">
              Aceitar
            </Button>
          )}
          {task?.status === 1 && (
            <Button
              onClick={() => {
                handleUpdateStatus(6);
                setAbreModalHistorico(true);
                setBtnClose(false);
              }}
              color="primary"
            >
              Não aceitar
            </Button>
          )}
          {task?.status === 2 && (
            <Button
              onClick={() => handleUpdateStatus(3, 'inicio')}
              color="primary"
            >
              Iniciar
            </Button>
          )}
          {task?.status === 3 && (
            <Button
              onClick={() => {
                handleUpdateStatus(4, 'conclusao');
                setAbreModalHistorico(true);
                setBtnClose(false);
              }}
              color="primary"
            >
              Concluir
            </Button>
          )}
          <Button
            onClick={() => history.push('/tarefa/' + task?.uuid)}
            color="primary"
          >
            Editar
          </Button>
          {task?.status !== 5 && task?.status !== 3 && task?.status !== 4 && (
            <Button
              onClick={() => {
                handleUpdateStatus(5);
                setAbreModalHistorico(true);
              }}
              color="secondary"
            >
              Cancelar Tarefa
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {abreModalHistorico ? (
        <HistoricoComponent
          vinculo={task?.uuid}
          tabela="acoes"
          fecha={() => setAbreModalHistorico(false)}
          abre={() => setAbreModalHistorico(true)}
          btnFechar={btnClose}
          refresh={() => handleHistorico(task?.uuid)}
        />
      ) : null}
      <SearchAcao setBusca={setBusca} busca={busca} />
    </S.ContainerChecklist>
  );
}

export default AcaoLista;
