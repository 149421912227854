import { useState } from 'react';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import { useEmpresa } from '../../hooks/empresa';
import Pagination from '@material-ui/lab/Pagination';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { Loading } from '../Loading/index';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function EmpresaTable({ onOpenAddModal, onOpenEditaModal }) {
  const {
    empresaLista,
    empresaPage,
    isLoading,
    pagination,
    deletarEmpresa,
    getEmpresaPorUuid
  } = useEmpresa();

  const [openConfirm, setOpenConfirmarDeletar] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [uuidEmpresa, setUuidEmpresa] = useState('');

  const handleSelect = async (event, value) => {
    await empresaPage(value);
  };

  const handleClickOpenEdit = async (uuid) => {
    await getEmpresaPorUuid(uuid);
    onOpenEditaModal();
  };

  function onOpenNewUserModal() {
    onOpenAddModal();
  }

  const handleClickOpenConfirm = async (nome, uuid) => {
    setOpenConfirmarDeletar(true);
    setNomeEmpresa(nome);
    setUuidEmpresa(uuid);
  };

  const handleCloseConfirm = async () => {
    setOpenConfirmarDeletar(false);
    setNomeEmpresa('');
    setUuidEmpresa('');
  };

  const handleDeletaConfirm = async (uuid) => {
    setOpenConfirmarDeletar(false);
    await deletarEmpresa(uuid);
    setNomeEmpresa('');
    setUuidEmpresa('');
  };

  return (
    <S.Container>
      <button type="button" onClick={onOpenNewUserModal}>
        Nova unidade
      </button>

      <div className="totalItens">
        <h1>Total de registros: {pagination[0]?.total}</h1>
      </div>

      {isLoading ? <Loading /> : null}
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Data de criação</th>
            <th className="status">Status</th>
            <th className="acao">Ações</th>
          </tr>
        </thead>
        <tbody>
          {empresaLista ? (
            empresaLista.map((lista) => (
              <tr key={lista.uuid}>
                <td
                  onClick={() =>
                    handleClickOpenEdit(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Título"
                >
                  {isLoading ? <Skeleton /> : lista.nome}
                </td>
                <td
                  onClick={() =>
                    handleClickOpenEdit(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Data de criação"
                >
                  {isLoading ? <Skeleton /> : lista.data_criacao?.br}
                </td>
                <td
                  className={lista.status === 1 ? 'ativo' : 'inativo'}
                  onClick={() =>
                    handleClickOpenEdit(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Status"
                >
                  {isLoading ? <Skeleton /> : <div></div>}
                </td>
                <td
                  className="deletar"
                  onClick={() =>
                    handleClickOpenConfirm(lista.titulo, lista.uuid)
                  }
                  data-label="Ações"
                >
                  <p>Deletar</p>
                </td>
              </tr>
            ))
          ) : (
            <tr className="zero">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
      <S.ContainerPagination>
        {pagination.length ? (
          <Pagination
            showLastButton={true}
            showFirstButton={true}
            color="primary"
            count={pagination[0].ultima_pagina}
            page={Number(pagination[0].pagina_atual)}
            onChange={handleSelect}
          />
        ) : null}
      </S.ContainerPagination>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Deletar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja deletar a empresa {nomeEmpresa || ''}, essa
            ação não poderá ser desfeita
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleCloseConfirm()}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleDeletaConfirm(uuidEmpresa || '')}
            color="secondary"
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  );
}
