import * as S from "./styles";
import { useState, useCallback, useEffect } from 'react';
import { useTag } from "../../hooks/tag"
import { TextField, FormControl, Dialog, DialogContent, DialogTitle, DialogActions, Button,Paper } from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { AdicionaTagitemModal } from "../../components/AdicionaTagitemModal/index";

import { EditaTagitemModal } from "../../components/EditaTagitemModal/index";
import { DeletaTagItemModal } from "../../components/DeletaTagItemModal/index";

import Draggable from 'react-draggable';
function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}



export function EditaTagModal({ abre, fecha, uuidAuditoria, itens, nomeTag, tipoTag, uuidTagModal, acao='editar'}) {
         
    const { EditaTagPorUUID, VisualizarTagPorUUID, tagItemPorUUID, VisualizarItemTagPorUUID } = useTag();

    const [nome_tag_modal, setNomeTagModal] = useState(nomeTag || '');
    
    const master = Number(localStorage.getItem('MMPROWORK'));
    const administrador = Number(localStorage.getItem('MPROWORK'));
    const gerente = Number(localStorage.getItem('GTPROWORK'));
    const gestor = Number(localStorage.getItem('GSPROWORK'));
    const analista = Number(localStorage.getItem('ANPROWORK'));

    const handleListaTag = useCallback(async () => {
        await VisualizarTagPorUUID(uuidAuditoria);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuidAuditoria]);


    const handleListaItemTag = useCallback(async () => {
        await VisualizarItemTagPorUUID(uuidTagModal);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuidTagModal]);

    useEffect(() => {
        handleListaItemTag();
    }, [handleListaItemTag]);

    const [vinculo_item, setVinculoitem] = useState('');
    const [nome_item, setNomeitem] = useState('');
    const [id_tag, setIdItem] = useState('');

    //Edita tag
    const handleEditaTag = async (e) => {
        e.preventDefault();
        let data = {
            titulo: nome_tag_modal,
            status: 1
        }

        let result = await EditaTagPorUUID(uuidTagModal, data);

         if(result.erro === false) {
            fecha();
            // eslint-disable-next-line react-hooks/rules-of-hooks
            handleListaTag();
        }
    };



    //ITEMS
    const [openConfirmItemTag, setModalItemTag] = useState(false);

    function handleAbreItemModalTag(){
        setModalItemTag(true);
    }

    function handleCloseItemModalTag(){
        setModalItemTag(false);
    }
    //EDITA ITEMS
    const [openConfirmEditaItemTag, setModalEditaItemTag] = useState(false);

    function handleAbreEditaItemModalTag(indice, vinculo, nome){
        setModalEditaItemTag(true);
        setIdItem(indice);
        setVinculoitem(vinculo);
        setNomeitem(nome);
    }

    function handleCloseEditaItemModalTag(){
        setModalEditaItemTag(false);
        setIdItem("");
        setVinculoitem("");
        setNomeitem("");
    }

    //DELETA ITEMS
    const [openConfirmDeletaItemTag, setModalDeletaItemTag] = useState(false);

    function handleDeletaItemModalTag(indice, vinculo, nome){
        setModalDeletaItemTag(true);
        setIdItem(indice);
        setVinculoitem(vinculo);
    }

    function handleCloseDeletaItemModalTag(){
        setModalDeletaItemTag(false);
        setIdItem("");
        setVinculoitem("");
        setNomeitem("");
    }
    return(
        <>
        <Dialog
            open={abre}
            onClose={fecha}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className="modal_dialog"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
             <p>Edita Tag</p>
            </DialogTitle>
            <DialogContent>
                <S.FormQrCode onSubmit={handleEditaTag}> 
                    <S.Column>
                        <FormControl variant="outlined" >
                            <TextField
                            variant="outlined"
                            label={'Nome'}
                            value={nome_tag_modal}
                            placeholder=""
                            onChange={event => setNomeTagModal(event.target.value)}
                            />
                        </FormControl>

                        <FormControl>
                            <S.TagLista onClick={() => handleAbreItemModalTag()}>
                                <h1>Item <p style={{cursor: 'pointer'}}  onClick={() => handleAbreItemModalTag()}>+</p></h1>
                                <div className="lista_item">
                                {tagItemPorUUID && tagItemPorUUID.map(lista =>  (
                                
                                    <div className="tag" key={lista.uuid} >
                                        <p>{lista.nome}</p>
                                        <div className="icon">
                                            <EditIcon style={{cursor: 'pointer'}}  onClick={() => handleAbreEditaItemModalTag(lista.id, lista.vinculo, lista.nome)} />
                                            <DeleteIcon style={{cursor: 'pointer'}}   onClick={() => handleDeletaItemModalTag(lista.id, lista.vinculo)}  />
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </S.TagLista>
                        </FormControl>
                    </S.Column>

                    <DialogActions>
                        {analista === 2 && ( gestor === 1 || administrador === 1 || master === 1 || gerente === 1) && acao === 'editar' ?
                        <Button autoFocus onClick={fecha} color="primary">
                            Cancelar
                        </Button>
                        :
                        <Button autoFocus onClick={fecha} color="primary">
                            Fechar
                        </Button>
                        }
                        {analista === 2 && ( gestor === 1 || administrador === 1 || master === 1 || gerente === 1) && acao === 'editar' ?
                        <Button
                            className="botao add"
                            variant="contained"
                            type="submit"
                        >
                        Cadastrar
                        </Button>
                        : null}
                    </DialogActions>
                </S.FormQrCode>
            </DialogContent>
        </Dialog>

        {openConfirmItemTag ? 
            <AdicionaTagitemModal abre={openConfirmItemTag} fecha={handleCloseItemModalTag} uuid={uuidTagModal} />
        : null}
        {openConfirmEditaItemTag ? 
            <EditaTagitemModal abre={openConfirmEditaItemTag} fecha={handleCloseEditaItemModalTag} vinculo={vinculo_item} indice={id_tag}  nome={nome_item} />
        : null}
        {openConfirmDeletaItemTag ? 
            <DeletaTagItemModal abre={openConfirmDeletaItemTag} fecha={handleCloseDeletaItemModalTag}  indice={id_tag} vinculo={vinculo_item}  uuidTag={uuidTagModal} />
        : null}
        </>
    )
}
