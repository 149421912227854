import { useState } from 'react';

import { useEstatistica } from '../../hooks/estatistica';
import { useDashboard } from '../../hooks/dashboard';
import { useTarefa } from '../../hooks/tarefa';
import { useEmpresa } from '../../hooks/empresa';

import InputMask from 'react-input-mask';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';

import { DownloadRelatorioModal } from '../../components/DownloadRelatorioModal';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Container, Divider } from './styles';

import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export function DashboardHeader() {
  const { empresaListaAtiva } = useEmpresa();
  const { searchEstatistica, searchEstatisticaAcao } = useEstatistica();
  const { filtraLista } = useDashboard();
  const { downloadRelatorioTarefa } = useTarefa();

  var data = new Date();
  var dia = String(data.getDate()).padStart(2, '0');
  var mes = String(data.getMonth() + 1).padStart(2, '0');
  var ano = data.getFullYear();

  const [abreModalRelatorio, setAbreModalRelatorio] = useState(false);
  const [tabelaRelatorio, setTabelaRelatorio] = useState(false);
  const [dataInicial, setDataInicio] = useState('01/' + mes + '/' + ano);
  const [dataFinal, setDataFinal] = useState(dia + '/' + mes + '/' + ano);
  const [empresa, setEmpresa] = useState('');

  const usuarioPermissao = Number(localStorage.getItem('MMPROWORK'));

  const handleFechaRelatorioModal = () => {
    setTabelaRelatorio('');
    setAbreModalRelatorio(false);
  };

  const handlePesquisa = async () => {
    let data = {
      dataFinal: moment(dataFinal, 'DD-MM-YYYY').format('YYYY-M-DD'),
      dataInicial: moment(dataInicial, 'DD-MM-YYYY').format('YYYY-M-DD'),
      empresa
    };
    await searchEstatistica(data, false);
    await searchEstatisticaAcao(data, false);
    await filtraLista(data);
  };

  const handleDownloadRelatorio = async () => {
    await downloadRelatorioTarefa(
      '?inicio=' + dataInicial + '&conclusao=' + dataFinal,
      false
    );
  };

  return (
    <Container>
      <div className="left">
        <div className="input">
          <FormControl>
            <InputMask
              type="text"
              mask="99/99/9999"
              placeholder="Data de inicio"
              onChange={(event) => setDataInicio(event.target.value)}
              value={dataInicial}
              maskChar=" "
            >
              {() => <TextField variant="outlined" label={'Data de inicio'} />}
            </InputMask>
          </FormControl>
          <Divider />
          <FormControl>
            <InputMask
              type="text"
              mask="99/99/9999"
              placeholder="Data de conclusão"
              onChange={(event) => setDataFinal(event.target.value)}
              value={dataFinal}
              maskChar=" "
            >
              {() => (
                <TextField variant="outlined" label={'Data de conclusão'} />
              )}
            </InputMask>
          </FormControl>
          <Divider />
          {usuarioPermissao === 1 ? (
            <FormControl
              className="form_control"
              variant="outlined"
              style={{ width: 210 }}
            >
              <InputLabel id="demo-simple-select-required-label">
                Unidade
              </InputLabel>
              <Select
                variant="outlined"
                onChange={(event) => setEmpresa(event.target.value)}
                value={empresa}
                label={'Unidade'}
                placeholder="Unidade"
              >
                <MenuItem value="">
                  <em>Selecione uma unidade</em>
                </MenuItem>
                {empresaListaAtiva.length > 0
                  ? empresaListaAtiva.map((lista) => (
                      <MenuItem key={lista.uuid} value={lista.id}>
                        {lista.nome}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          ) : null}
        </div>
        <Divider />
        <button type="button" onClick={() => handlePesquisa()}>
          <SearchIcon style={{ cursor: 'pointer' }} />
          <span>Buscar</span>
        </button>
      </div>
      <button
        className="buttonRelatorio"
        type="button"
        onClick={() => handleDownloadRelatorio()}
      >
        <CloudDownloadIcon style={{ cursor: 'pointer' }} />
        <span>Download relatório</span>
      </button>
      {abreModalRelatorio && (
        <DownloadRelatorioModal
          abre={abreModalRelatorio}
          fecha={handleFechaRelatorioModal}
          tabela={tabelaRelatorio}
        />
      )}
    </Container>
  );
}
