import api from "../services/api";
import {createContext, useState, useContext} from 'react';
import { toast } from 'react-toastify';

const PastaContext = createContext({});

export const PastaProvider = (props) =>  {
    const [empresaListaAtiva, setPastaListaAtiva] = useState([]);
    const [verClientePorUuid, setVerClientePorUUID] = useState([]);
    const [isLoadingCliente, setIsLoadingCliente] = useState(false);

    const [verTarefaPorUuid, setVerTarefaPorUUID] = useState([]);
    const [isLoadingTarefa, setIsLoadingTarefa] = useState(false);
    
    
    const [paginationTarefa, setPaginationTarefa] = useState([] || '');

    const [verHistoricoPorUuid, setVerHistoricoPorCliente] = useState([]);
    const [isLoadingHistorico, setIsLoadingHistorico] = useState(false);


    async function VisualizarClientePorUUID(uuid) {
        try {
            setIsLoadingCliente(true);
            const responseedit = await api.get('/cliente/'+uuid); 

            setVerClientePorUUID([responseedit.data]);

            setIsLoadingCliente(false);
            return {
                erro: false,
                dados: [responseedit.data]
            }

        } catch (err) {
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingCliente(false);
            return {
                erro: true
            }
        }
    }
       
    async function VisualizarEmpresa(uuid) {
        try {
            setIsLoadingCliente(true);
            const response = await api.get('/empresa?status=1'); 

            setPastaListaAtiva(response.data.itens);

            setIsLoadingCliente(false);
            return {
                erro: false,
                dados: response.data.itens
            }

        } catch (err) {
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingCliente(false);
            return {
                erro: true
            }
        }
    }  

    // EDITANDO OS CLIENTES

    async function editCliente(dadosCliente, uuid) {
        try {

            setIsLoadingCliente(true);

            const response = await api.put('/cliente/'+uuid, dadosCliente); 

            const responsepage = await api.get('/cliente/'+uuid); 

            setVerClientePorUUID([responsepage.data]);

            setIsLoadingCliente(false);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });

        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setIsLoadingCliente(false); 
        }
    } 

    //TAREFAS
    async function VisualizarTarefaPorUUID(uuid) {
        try {
            setIsLoadingTarefa(true);
            const responseedit = await api.get('/tarefa?cliente='+uuid); 

                if(responseedit.data.itens.length > 0){
                    setVerTarefaPorUUID([responseedit.data.itens]);
                    const data = [{
                            'pagina_atual' : responseedit.data.pagina_atual,
                            'proxima_pagina' : responseedit.data.proxima_pagina,
                            'pagina_anterior' : responseedit.data.pagina_anterior,
                            'ultima_pagina' : responseedit.data.ultima_pagina,
                            'total' : responseedit.data.total
                        }
                    ];
                    setPaginationTarefa(data);
                    setIsLoadingTarefa(false);
                    return {
                        erro: false
                    }
                }

        } catch (err) {
            toast.error(err.response?.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setIsLoadingTarefa(false);
            return {
                erro: true
            }
        }
    }


    
    async function deletarTarefa(uuid, recorrente) {
        try {
            if(recorrente !== undefined){
                setIsLoadingTarefa(true);
                let responseRecorrente =  await api.delete('/recorrente/'+recorrente);  
                                
                if(responseRecorrente.data.message){
                        let response = await api.delete('/tarefa/'+uuid);   
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        }); 
                }  
                
                setIsLoadingTarefa(false);
                return {
                    erro: false
                }
            }else{
                setIsLoadingTarefa(true);
                    let response = await api.delete('/tarefa/'+uuid);   
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                
                setIsLoadingTarefa(false); 
                return {
                    erro: false
                }
            }
  

        } catch (err) {
            if(err.response.data.message === 'Tarefa recorrente encontrada!'){
                let responseRecorrente =  await api.delete('/recorrente/'+recorrente);  
                             
                if(responseRecorrente.data.message){
                    let response = await api.delete('/tarefa/'+uuid);   
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    }); 
                }  
                
                window.location.reload();
                return {
                    erro: false
                }
            }else{
                toast.success(err.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

                setIsLoadingTarefa(false); 
                return {
                    erro: true
                }
            }


            
        }
    }
    // Por cliente
    async function tarefaPageCliente(uuid, page) {
        try {
            setIsLoadingTarefa(true);
            
            const response = await api.get('/tarefa?cliente='+uuid+'&page='+page);             

            setVerTarefaPorUUID([response.data.itens]);
            const data = [{
                    'pagina_atual' : response.data.pagina_atual,
                    'proxima_pagina' : response.data.proxima_pagina,
                    'pagina_anterior' : response.data.pagina_anterior,
                    'ultima_pagina' : response.data.ultima_pagina,
                    'total' : response.data.total
                }
            ];
            setPaginationTarefa(data);
            setIsLoadingTarefa(false);
            


        } catch (err) {
            setIsLoadingTarefa(false); 
        }
    }



    async function visualizarHistoricoPorCliente(uuid, modal) {
        try {
            setIsLoadingHistorico(true);
            const responseedit = await api.get('/historico/'+uuid+'/clientes'); 

            setVerHistoricoPorCliente(responseedit.data.itens);
           
            setIsLoadingHistorico(false);
            return {
                erro: false
            }

        } catch (err) {
            setIsLoadingHistorico(true);
           
            setIsLoadingHistorico(false);
            return {
                erro: true,
                message: err.response?.data.message
            }
        }
    }



    
    // Pesquisar Catálogo
    
    async function searchTarefa(search, Modal, uuid) {
        try {
            setIsLoadingTarefa(true);
            
            if(search.acao === '' && search.cliente === '' && search.status === ''  && search.recorrente === '' && search.inicio_previsto_inicio === '' && search.inicio_previsto_final === ''  && search.origem === ''){
                const responseedit = await api.get('/tarefa?cliente='+uuid+'&recorrente='+search.recorrente+'&acao='+search.acao+'&status='+search.status+'&inicio_previsto_inicio='+search.inicio_previsto_inicio+'&inicio_previsto_final='+search.inicio_previsto_final+'&origem='+search.origem); 

                if(responseedit.data.itens.length > 0){
                    setVerTarefaPorUUID([responseedit.data.itens]);
                    const data = [{
                            'pagina_atual' : responseedit.data.pagina_atual,
                            'proxima_pagina' : responseedit.data.proxima_pagina,
                            'pagina_anterior' : responseedit.data.pagina_anterior,
                            'ultima_pagina' : responseedit.data.ultima_pagina,
                            'total' : responseedit.data.total
                        }
                    ];
                    setPaginationTarefa(data);
                    setIsLoadingTarefa(false);
                    return {
                        erro: false
                    }
                }
            } else {
                 const response = await api.get('/tarefa?cliente='+uuid+'&recorrente='+search.recorrente+'&acao='+search.acao+'&status='+search.status+'&inicio_previsto_inicio='+search.inicio_previsto_inicio+'&inicio_previsto_final='+search.inicio_previsto_final+'&origem='+search.origem); 
                 if(response.data.error === true ){
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                 }else {
                    localStorage.setItem('buscaAtivaTarefa', 'ativa');
                    localStorage.setItem('salvarBuscaTarefa', JSON.stringify(search));
                    
                    setVerTarefaPorUUID([response.data.itens]);
                    const data = [{
                        'quantidade_pagina' : response.data.quantidade_pagina,
                        'pagina_atual' : response.data.pagina_atual,
                        'proxima_pagina' : response.data.proxima_pagina,
                        'pagina_anterior' : response.data.pagina_anterior,
                        'ultima_pagina' : response.data.ultima_pagina,
                        'total' : response.data.total
                        }
                    ];
                    setPaginationTarefa(data);
                 }
            }
            
            setIsLoadingTarefa(false);
            Modal();
            

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingTarefa(false); //pegando o response da requisição da api
        }
    }

    async function limpaTarefa(uuid) {
        try {
            let keysToRemove = ["buscaAtivaTarefa", "salvarBuscaTarefa"];

            keysToRemove.forEach(k =>
                localStorage.removeItem(k));
                window.location.reload();

            setIsLoadingTarefa(true);
              const responseedit = await api.get('/tarefa?cliente='+uuid); 

                if(responseedit.data.itens.length > 0){
                    setVerTarefaPorUUID([responseedit.data.itens]);
                    const data = [{
                            'pagina_atual' : responseedit.data.pagina_atual,
                            'proxima_pagina' : responseedit.data.proxima_pagina,
                            'pagina_anterior' : responseedit.data.pagina_anterior,
                            'ultima_pagina' : responseedit.data.ultima_pagina,
                            'total' : responseedit.data.total
                        }
                    ];
                    setPaginationTarefa(data);
                    setIsLoadingTarefa(false);
                    return {
                        erro: false
                    }
                }
            setIsLoadingTarefa(false);

        } catch (err) {            
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            setIsLoadingTarefa(false); //pegando o response da requisição da api
        }
    }



    // Pesquisar Catálogo
  
    async function searchTarefaModal(search, uuid) {
        try {
            setIsLoadingTarefa(true);

            let url = "?cliente="+uuid;

            if(search.status){
            url += "&status=" + search.status;
            }
            if(search.acao){
            url += "&acao=" + search.acao;
            }
            if(search.id){
            url += "&id=" + search.id;
            }
            if(search.problema){
            url += "&problema=" + search.problema;
            }
            if(search.responsavel){
            url += "&responsavel=" + search.responsavel;
            }
            if(search.cliente){
            url += "&cliente=" + search.cliente;
            }
            if(search.inicioPrevistoInico){
            url += "&inicio_previsto_inicio=" + search.inicioPrevistoInico;
            }
            if(search.inicioPrevistoFinal){
            url += "&inicio_previsto_final=" + search.inicioPrevistoFinal;
            }
            if(search.conclusaoPrevistaInicio){
            url += "&conclusao_prevista_inicio=" + search.conclusaoPrevistaInicio;
            }
            if(search.conclusaoPrevistaFinal){
            url += "&conclusao_prevista_final=" + search.conclusaoPrevistaFinal;
            }
            if(search.inicio){
            url += "&inicio=" + search.inicio;
            }
            if(search.conclusao){
            url += "&conclusao=" + search.conclusao;
            }
            if(search.origem){
            url += "&origem=" + search.origem;
            }
            if(search.nota){
            url += "&nota=" + search.nota;
            }
            if(search.codigo){
            url += "&auditoria=" + search.codigo;
            }
            const response = await api.get('/tarefa'+url);

            if (response.data.error === true) {
            toast.error(response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            } else {
            localStorage.setItem('salvarBuscaAuditoria', url);

            setVerTarefaPorUUID([response.data.itens]);
            const data = [
                {
                quantidade_pagina: response.data.quantidade_pagina,
                pagina_atual: response.data.pagina_atual,
                proxima_pagina: response.data.proxima_pagina,
                pagina_anterior: response.data.pagina_anterior,
                ultima_pagina: response.data.ultima_pagina,
                total: response.data.total
                }
            ];
            setPaginationTarefa(data);
            }

            setIsLoadingTarefa(false);
        } catch (err) {
            setIsLoadingTarefa(false);
            setVerTarefaPorUUID([]);
        }
    }

    return (
        <PastaContext.Provider value={{empresaListaAtiva, verClientePorUuid, isLoadingCliente, VisualizarClientePorUUID, VisualizarEmpresa, editCliente, VisualizarTarefaPorUUID, paginationTarefa, verTarefaPorUuid, searchTarefaModal, isLoadingTarefa, deletarTarefa, tarefaPageCliente,verHistoricoPorUuid, isLoadingHistorico, visualizarHistoricoPorCliente, limpaTarefa, searchTarefa}}>  
            {props.children}
        </PastaContext.Provider>
    )

}



export function usePasta() {
    const context = useContext(PastaContext);

    return context;
}
