import React from 'react';

import { isAuthenticated } from '../services/auth';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

import Login from '../pages/Login';
import Home from '../pages/Home';
import NovaSenha from '../pages/NovaSenha';
import DashboardTarefa from '../pages/DashboardTarefa';
import DashboardGeral from '../pages/DashboardGeral';
import DashboardVisitas from '../pages/DashboardVisitas';
import { Perfil } from '../pages/Perfil/index';
import { Usuario } from '../pages/Usuario/index';
import { NewUser } from '../pages/NewUser/index';
import { EditUser } from '../pages/EditUser/index';
import { Cliente } from '../pages/Cliente/index';
import { NewCliente } from '../pages/NewCliente/index';
import { EditCliente } from '../pages/EditCliente/index';
import { VisualizarCliente } from '../pages/VisualizarCliente/index';
import { Tarefa } from '../pages/Tarefa/index';
import { Acao } from '../pages/Acao';
import { EditAcao } from '../pages/EditAcao';
import { NewAcao } from '../pages/NewAcao';
import { MinhasTarefas } from '../pages/MinhasTarefas/index';
import { NewTarefa } from '../pages/NewTarefa/index';
import { EditTarefa } from '../pages/EditTarefa/index';
import { VisualizarTarefa } from '../pages/VisualizarTarefa/index';
import { CheckList } from '../pages/CheckList/index';
import { EditChecklist } from '../pages/EditChecklist/index';
import { CheckListTarefa } from '../pages/CheckListTarefa/index';
import { DetalheResposta } from '../pages/DetalheResposta/index';
import { DetalheGrupo } from '../pages/DetalheGrupo/index';
import { Agenda } from '../pages/Agenda/index';
import { NotFound } from '../pages/NotFound/index';
import { SemPermissao } from '../pages/SemPermissao/index';
import { Origem } from '../pages/Origem/index';
import { ItemRespostas } from '../pages/ItemRespostas/index';
import { Empresa } from '../pages/Empresa/index';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

const master = Number(localStorage.getItem('MMPROWORK'));
const administrador = Number(localStorage.getItem('MPROWORK'));
const gerente = Number(localStorage.getItem('GTPROWORK'));
const gestor = Number(localStorage.getItem('GSPROWORK'));

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/nova-senha/:token" component={NovaSenha} />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/perfil" component={Perfil} />
      {administrador === 1 || gerente === 1 || master === 1 ? (
        <PrivateRoute exact path="/usuario" component={() => <Usuario />} />
      ) : null}
      {administrador === 1 || gerente === 1 || master === 1 ? (
        <PrivateRoute
          exact
          path="/usuario/novo"
          component={() => <NewUser />}
        />
      ) : null}
      {administrador === 1 || gerente === 1 || master === 1 ? (
        <PrivateRoute
          exact
          path="/usuario/:id"
          component={() => <EditUser />}
        />
      ) : null}
      <PrivateRoute exact path="/pasta" component={() => <Cliente />} />
      <PrivateRoute exact path="/tarefa" component={() => <Acao />} />
      {administrador === 1 || gerente === 1 || master === 1 || gestor === 1 ? (
        <PrivateRoute
          exact
          path="/tarefa/nova"
          component={() => <NewAcao />}
        />
      ) : null}
      {administrador === 1 || gerente === 1 || master === 1 || gestor === 1 ? (
        <PrivateRoute
          exact
          path="/tarefa/:id"
          component={() => <EditAcao />}
        />
      ) : null}
      <PrivateRoute exact path="/pasta/novo" component={() => <NewCliente />} />
      <PrivateRoute exact path="/pasta/:id" component={() => <EditCliente />} />
      <PrivateRoute
        exact
        path="/pasta/visualiza/:id"
        component={() => <VisualizarCliente />}
      />
      <PrivateRoute exact path="/checklist" component={() => <Tarefa />} />
      <PrivateRoute
        exact
        path="/minhas-checklists"
        component={() => <MinhasTarefas />}
      />
      <PrivateRoute
        exact
        path="/minhas-checklists/:id"
        component={() => <EditTarefa />}
      />
      <PrivateRoute
        exact
        path="/checklist/novo/:id"
        component={() => <NewTarefa />}
      />
      <PrivateRoute
        exact
        path="/checklist/:id"
        component={() => <EditTarefa />}
      />
      <PrivateRoute
        exact
        path="/checklist/visualiza/:id"
        component={() => <VisualizarTarefa />}
      />
      <PrivateRoute
        exact
        path="/dashboard-checklist"
        component={() => <DashboardTarefa />}
      />
      <PrivateRoute
        exact
        path="/checklist/questionario/:id"
        component={() => <CheckListTarefa />}
      />
      {administrador === 1 || gerente === 1 || master === 1 ? (
        <PrivateRoute
          exact
          path="/questionario"
          component={() => <CheckList />}
        />
      ) : null}
      {administrador === 1 || gerente === 1 || master === 1 || gestor === 1 ? (
        <PrivateRoute
          exact
          path="/questionario/:id"
          component={() => <EditChecklist />}
        />
      ) : null}
      {/* <PrivateRoute exact path="/questionario-respostas" component={() => (
                <Resposta />
            )} /> */}
      <PrivateRoute
        exact
        path="/questionario-respostas/:id"
        component={() => <DetalheResposta />}
      />
      {administrador === 1 || gerente === 1 || master === 1 || gestor === 1 ? (
        <PrivateRoute exact path="/agenda" component={() => <Agenda />} />
      ) : null}
      <PrivateRoute
        exact
        path="/dashboard"
        component={() => <DashboardGeral />}
      />
      <PrivateRoute
        exact
        path="/dashboard/visitas"
        component={() => <DashboardVisitas />}
      />
      {administrador === 1 || gerente === 1 || master === 1 ? (
        <PrivateRoute exact path="/origem" component={() => <Origem />} />
      ) : null}
      <PrivateRoute
        exact
        path="/grupo/:id/:checklist"
        component={() => <DetalheGrupo />}
      />
      <PrivateRoute
        exact
        path="/item-respostas"
        component={() => <ItemRespostas />}
      />
      {master === 1 ? (
        <PrivateRoute exact path="/empresas" component={() => <Empresa />} />
      ) : null}
      <PrivateRoute exact path="/sem-permissao" component={SemPermissao} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
