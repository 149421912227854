import styled from 'styled-components';

export const TopbarStyle = styled.div`
  width: 100%;
  height: 80px;
  background-color: #bd0007;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid #eff3ff;
  margin-left: 228px;
  @media (max-width: 1300px) {
    margin-left: 0px;
  }
  button {
    background: none;
  }
  svg {
    font-size: 1.4em;
    margin-right: 20px;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-right: 228px;
  h2 {
    font-size: 1.4em;
  }
  svg {
    font-size: 1.4em;
    margin-right: 20px;
    cursor: pointer;
  }
  .esquerdo {
    display: flex;
    align-items: center;
  }
  @media (max-width: 1300px) {
    margin-right: 0px;
  }
`;

export const VoltarPagina = styled.a`
  display: flex;
  align-items: center;
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  .voltar_page {
    margin-right: 20px;
    font-size: 22px;
    cursor: pointer;
  }
`;

export const ButtonFornecedor = styled.a`
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
`;

export const End = styled.div`
  display: flex;
  align-items: center;

  .close_search {
    margin-right: 5px;
    a {
      background-color: #181818;
      color: rgb(255, 255, 255);
    }
    &:hover,
    &:focus {
      border-color: #181818 !important;
    }
  }
`;
