import * as S from "./styles";
import Skeleton from '@material-ui/lab/Skeleton';
import { useUsuario } from "../../hooks/user";
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom"
import { Loading } from "../Loading/index";


export function UsuarioTable({verUsuarioModal}) {
    let history = useHistory();
    const { usuarioLista, userPage, isLoading, pagination, VisualizarUsuarioPorUUID} = useUsuario();

    const handleSelect = async (event, value) => {
        await userPage(value);
    }
    
    const handleClickOpenView = async (uuid) => {
        verUsuarioModal();
        await VisualizarUsuarioPorUUID(uuid);
        
    };

    function onOpenNewUserModal() {
        history.push("/usuario/novo");
    }

    let gerente = Number(localStorage.getItem('GTPROWORK'));
    let administrador = Number(localStorage.getItem('MPROWORK'));
    let master = Number(localStorage.getItem('MMPROWORK'));
    
    return (
        <S.Container>

            {administrador === 1 || gerente === 1 || master === 1 ?
                <button type="button" onClick={onOpenNewUserModal}>
                Novo usuário
                </button>
            : null}
            
            <div className="totalItens"><h1>Total de registros: {pagination[0]?.total}</h1></div>

            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Documento</th>
                        <th className="status">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? 
                        <Loading />
                    :
                    usuarioLista && usuarioLista.map(lista =>  (
                        <tr
                            key={lista.uuid}
                            onClick={() => handleClickOpenView(lista.uuid,pagination[0].pagina_atual)}
                        >
                            <td>{isLoading ? <Skeleton /> : lista.nome}</td>
                            <td>
                                {isLoading ? <Skeleton /> : lista.cpf?.br}
                            </td>
                            <td className={lista.status === 1 ? 'ativo' : 'inativo'}>
                                {isLoading ? <Skeleton /> : <div></div>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <S.ContainerPagination>
                {pagination.length > 0 ?
                <Pagination
                    showLastButton={true}
                    showFirstButton={true}
                    color="primary"
                    count={pagination[0].ultima_pagina}
                    page={Number(pagination[0].pagina_atual)}
                    onChange={handleSelect}
                />
                : null}
            </S.ContainerPagination>
        </S.Container>
    );
}
