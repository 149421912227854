import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import * as S from './styles';
import 'moment/locale/pt-br';
import { useTarefa } from '../../hooks/tarefa';
import { toast } from 'react-toastify';
import { AiFillWarning } from 'react-icons/ai';
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';

moment.locale('pt-br');

export function CalendarioComponent2() {
  let history = useHistory();
  const {
    tarefaListaAgenda,
    isLoadingAgenda,
    searchTarefaAgenda,
    paginationAgenda,
    tarefaAgendaPage,
    agenda,
    agendaStart,
    setTarefaListaAgenda
  } = useTarefa();

  const allMonthValues = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];

  // Seleciona a data
  const [selectedDate, setSelectedDate] = useState(new Date());

  // State for text above calander
  const [calendarText, setCalendarText] = useState(``);

  // Function to update selected date and calander text
  const handleDateChange = async (value) => {
    setSelectedDate(value);
    setCalendarText(moment(`${value}`).format('MMMM DD [de] YYYY'));

    let data = {
      inicio_previsto_inicio: moment(`${value}`).format('DD/MM/YYYY')
    };
    await searchTarefaAgenda(data);
  };

  // Function to handle selected Year change
  const handleYearChange = (value) => {
    const yearValue = value.getFullYear();
    setCalendarText(`${yearValue}`);
  };

  const dateMonth = async (mes, ano) => {
    let mesAno = moment().format(mes + '/' + ano);
    await agendaStart(mesAno);
  };

  // Function to handle selected Month change
  const handleMonthChange = (value) => {
    setTarefaListaAgenda([]);
    dateMonth(value.getMonth() + 1, value.getFullYear());
    const monthValue = allMonthValues[value.getMonth()];
    setCalendarText(`${monthValue}`);
  };

  const handleButtonsChange = (value) => {
    setTarefaListaAgenda([]);
    dateMonth(value.getMonth() + 1, value.getFullYear());
  };

  const Msg = ({
    data_criacao,
    acao,
    inicio_previsto,
    conclusao_prevista,
    cliente,
    status,
    inicio,
    conclusao,
    uuid,
    empresa
  }) => (
    <div>
      <p>
        <b>Unidade</b>: {empresa}
      </p>
      <p>
        <b>Pasta</b>: {cliente}
      </p>
      <p>
        <b>Ação</b>: {acao}
      </p>
      <p>
        <b>Data de criação</b> {data_criacao}
      </p>
      {status === 1 && (
        <>
          <p>
            <b>Data de início previsto</b>: {inicio_previsto}
          </p>
          <p>
            <b>Data de conclusão prevista</b>: {conclusao_prevista}
          </p>
        </>
      )}
      {status === 2 && (
        <>
          <p>
            <b>Data de início</b>: {inicio}
          </p>
          <p>
            <b>Data de conclusão</b>: {conclusao}
          </p>
        </>
      )}
      <p>
        <b>Status</b>:{' '}
        {status === 1
          ? 'Pendente'
          : status === 2
          ? 'Concluída'
          : status === 3
          ? 'Atrasada'
          : null}
      </p>
      <button
        className="btnPadrao"
        onClick={() => window.open('/checklist/' + uuid, '_blank')}
      >
        Ir para a auditoria
      </button>
    </div>
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = async (
    data_criacao,
    acao,
    inicio_previsto,
    conclusao_prevista,
    cliente,
    status,
    inicio,
    conclusao,
    uuid,
    empresa
  ) => {
    toast(
      <Msg
        data_criacao={data_criacao}
        acao={acao}
        inicio_previsto={inicio_previsto}
        conclusao_prevista={conclusao_prevista}
        cliente={cliente}
        status={status}
        inicio={inicio}
        conclusao={conclusao}
        uuid={uuid}
        empresa={empresa}
      />,
      {
        icon: <AiFillWarning />,
        position: toast.POSITION.TOP_CENTER,
        toastId: 'toastAgenda',
        autoClose: false
      }
    );
  };

  const handleSelect = async (event, value) => {
    await tarefaAgendaPage(value);
  };

  const dateStart = async () => {
    let data = {
      inicio_previsto_inicio: moment().format('DD/MM/YYYY')
    };
    await searchTarefaAgenda(data);
  };

  useEffect(() => {
    dateStart();
    dateMonth(moment().format('MM'), moment().format('YYYY'));
  }, []);
  return (
    <S.Container>
      <h2 className="calander-details">
        {calendarText || moment().format('MMMM DD [de] YYYY')}
      </h2>
      <Calendar
        onClickMonth={handleMonthChange}
        onClickYear={handleYearChange}
        onActiveStartDateChange={({ activeStartDate }) =>
          handleButtonsChange(activeStartDate)
        }
        onChange={handleDateChange}
        value={selectedDate}
        tileContent={(date) => {
          let dia = date.date.getDate();
          let mesAgenda = date.date.getMonth() + 1;
          if (agenda && agenda?.data) {
            if (agenda?.data[dia] && agenda?.data[dia]?.length > 0) {
              let mes = agenda?.data[dia][0].inicio_previsto.valor.split('-');
              let mesAtual = mes[1].replace('0', '');
              mesAtual = parseInt(mesAtual);
              mesAgenda = parseInt(mesAgenda);

              if (mesAgenda == mesAtual) {
                var countPendente = 0;
                var countConcluida = 0;
                var countIniciado = 0;
                var countAtrasado = 0;
                agenda?.data[dia].map((item) => {
                  if (item.status_texto === 'Pendente') {
                    countPendente++;
                  } else if (item.status_texto === 'Concluída') {
                    countConcluida++;
                  } else if (item.status_texto === 'Iniciado') {
                    countIniciado++;
                  } else {
                    countAtrasado++;
                  }
                });
              }
            }
            return (
              <div className="circleBackground">
                {countPendente > 0 && (
                  <div className="circle pendente">
                    <h1>{countPendente}</h1>
                  </div>
                )}
                {countConcluida > 0 && (
                  <div className="circle concluida">
                    <h1>{countConcluida}</h1>
                  </div>
                )}
                {countIniciado > 0 && (
                  <div className="circle iniciado">
                    <h1>{countIniciado}</h1>
                  </div>
                )}
                {countAtrasado > 0 && (
                  <div className="circle atrasada">
                    <h1>{countAtrasado}</h1>
                  </div>
                )}
              </div>
            );
          }
        }}
      />
      <div className="legenda">
        <p className="pendente">Pendentes</p>
        <p className="iniciado">Iniciada</p>
        <p className="atrasada">Atrasadas</p>
        <p className="concluida">Concluídas</p>
      </div>
      <div className="container">
        <div className="section">
          <div>
            <div>
              <h2>Auditorias</h2>
            </div>
            <div>
              <table className="events-list">
                <tbody>
                  {isLoadingAgenda ? (
                    <tr>
                      <td>
                        <Skeleton variant="text" width={'100%'} height={30} />
                      </td>
                    </tr>
                  ) : (
                    tarefaListaAgenda &&
                    tarefaListaAgenda.map((lista) => {
                      let classe = 'event-date';
                      if (lista.status_texto === 'Pendente') {
                        classe += ' pendente';
                      } else if (lista.status_texto === 'Concluída') {
                        classe += ' concluida';
                      } else if (lista.status_texto === 'Iniciado') {
                        classe += ' iniciado';
                      } else {
                        classe += ' atrasada';
                      }

                      return (
                        <tr key={lista.uuid}>
                          <td>
                            <div className={classe}>
                              <p
                                onClick={() =>
                                  handleClick(
                                    lista.data_criacao.br,
                                    lista.acao,
                                    lista.inicio_previsto.br,
                                    lista.conclusao_prevista.br,
                                    lista.cliente.nome,
                                    lista.status,
                                    lista.inicio.br,
                                    lista.conclusao.br,
                                    lista.uuid,
                                    lista.empresa_nome
                                  )
                                }
                              >
                                {lista.acao + ' | ' + lista.empresa_nome}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <S.ContainerPagination>
                {paginationAgenda.length ? (
                  <Pagination
                    showLastButton={true}
                    showFirstButton={true}
                    color="primary"
                    count={paginationAgenda[0].ultima_pagina}
                    page={Number(paginationAgenda[0].pagina_atual)}
                    onChange={handleSelect}
                  />
                ) : null}
              </S.ContainerPagination>
            </div>
          </div>
        </div>
      </div>
    </S.Container>
  );
}
