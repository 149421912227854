import React from "react";
import {Box} from '@material-ui/core'

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import {Dashboard} from "../../components/DashboardVisitas";
import { DashboardProvider } from "../../hooks/dashboard";

const DashboardVisitas = () => {
  return (
      <div style={{display: 'flex'}}>
          <Sidebar activePage={'6'} />
          <div  style={{width: '100%'}}>
            <DashboardProvider>
                <Topbar pageTitle={'Dashboard - Visitas'} />
                <Box className="container_admin">

                      <Dashboard />

                </Box>
            </DashboardProvider>
          </div>
      </div>
  );
};

export default DashboardVisitas;

