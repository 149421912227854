/* import React from "react";
import {Box} from '@material-ui/core'

import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/index";
import { PerfilProvider } from "../hooks/perfil";
import HomeTela from "../components/HomeTela/index";
import { EstatisticaProvider } from "../hooks/estatistica"; */

const Home = () => {

    return window.location.pathname = 'dashboard';

/*   return (
    <PerfilProvider>
      <EstatisticaProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'1'} />
            <div  style={{width: '100%'}}>
              <Topbar pageTitle={'Home'} />
              <Box className="container_admin">
                <HomeTela />
              </Box>
            </div>
        </div>
      </EstatisticaProvider>
    </PerfilProvider>
  ); */
};

export default Home;

