import api from '../services/api';
import { login } from '../services/auth';
import { Redirect } from 'react-router-dom';

export const userLogin = async ({ cpf, password }) => {
  return new Promise((resolve, reject) => {
    api
      .post('/auth/login', { cpf, password, painel: true })
      .then((response) => {
        let auditor = Number(response.data.dados.auditor);
        let administrador = Number(response.data.dados.administrador);
        let gerente = Number(response.data.dados.gerente);
        let gestor = Number(response.data.dados.gestor);
        let analista = Number(response.data.dados.analista);

        if (
          auditor === 1 &&
          administrador === 2 &&
          gerente === 2 &&
          gestor === 2 &&
          analista === 2
        ) {
          reject(
            'Você não tem permissão para acessar esse painel, apenas o app!'
          );
        } else {
          login(response.data.access_token);

          localStorage.setItem('NPROWORK', response.data.dados.nome);
          localStorage.setItem('LPROWORK', response.data.dados.uuid);
          localStorage.setItem('ANPROWORK', response.data.dados.analista);
          localStorage.setItem('ADPROWORK', response.data.dados.auditor);
          localStorage.setItem('GSPROWORK', response.data.dados.gestor);
          localStorage.setItem('GTPROWORK', response.data.dados.gerente);
          localStorage.setItem('MPROWORK', response.data.dados.administrador);
          localStorage.setItem('MMPROWORK', response.data.dados.master);
          setTimeout(() => {
            return window.location.pathname = 'dashboard';
          }, 1000);
          resolve();
        }
      })
      .catch((err) => {
        if (err.response) {
          let erro = err.response.data.message;
          reject(erro);
        }
      });
  });
};

export const userSenha = async ({ email, cliente }) => {
  return new Promise((resolve, reject) => {
    api
      .post('/auth/esqueci-minha-senha', { email, cliente })
      .then((response) => {
        if (response) {
          let mensagem = response.data.message;
          resolve(mensagem);
        }
      })
      .catch((err) => {
        if (err.response) {
          let erro = err.response.data.message;
          reject(erro);
        }
      });
  });
};

export const userNovaSenha = async ({ nova_senha, confirmar_senha, token }) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  return new Promise((resolve, reject) => {
    api
      .put('/usuario/nova-senha', { nova_senha, confirmar_senha }, config)
      .then((response) => {
        if (response) {
          let mensagem = response.data.message;
          resolve(mensagem);
        }
      })
      .catch((err) => {
        if (err.response) {
          let erro = err.response.data.message;
          reject(erro);
        }
      });
  });
};
