import { useState } from 'react';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import { useCliente } from '../../hooks/cliente';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { Loading } from '../Loading/index';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function ClienteTable() {
  let history = useHistory();
  const { clienteLista, clientePage, isLoading, pagination, deletarCliente } =
    useCliente();

  const master = Number(localStorage.getItem('MMPROWORK'));
  const administrador = Number(localStorage.getItem('MPROWORK'));
  const gerente = Number(localStorage.getItem('GTPROWORK'));
  const gestor = Number(localStorage.getItem('GSPROWORK'));
  const analista = Number(localStorage.getItem('ANPROWORK'));

  const [openConfirm, setOpenConfirmarDeletar] = useState(false);
  const [nomeCliente, setNomeCliente] = useState('');
  const [uuidCliente, setUuidCliente] = useState('');

  const handleSelect = async (event, value) => {
    await clientePage(value);
  };

  const handleClickOpenEdit = async (uuid) => {
    if (
      administrador === 1 ||
      master === 1 ||
      (administrador === 2 &&
        gerente === 1 &&
        gestor === 2 &&
        analista === 2) ||
      (administrador === 2 && gerente === 2 && gestor === 1 && analista === 2)
    ) {
      history.push('/pasta/' + uuid);
    } else if (
      administrador === 2 &&
      gerente === 2 &&
      gestor === 2 &&
      analista === 1
    ) {
      history.push('/pasta/visualiza/' + uuid);
    }
  };

  function onOpenNewUserModal() {
    history.push('/pasta/novo');
  }

  const handleClickOpenConfirm = async (nome, uuid) => {
    setOpenConfirmarDeletar(true);
    setNomeCliente(nome);
    setUuidCliente(uuid);
  };

  const handleCloseConfirm = async () => {
    setOpenConfirmarDeletar(false);
    setNomeCliente('');
    setUuidCliente('');
  };

  const handleDeletaConfirm = async (uuid) => {
    setOpenConfirmarDeletar(false);
    await deletarCliente(uuid);
    setNomeCliente('');
    setUuidCliente('');
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <S.Container>
          {administrador === 1 || gerente === 1 || master === 1 ? (
            <button type="button" onClick={onOpenNewUserModal}>
              Nova Pasta
            </button>
          ) : null}
          <div className="totalItens">
            <h1>Total de registros: {pagination[0]?.total}</h1>
          </div>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Data de criação</th>
                <th>Unidade</th>
                <th className="status">Status</th>
                {administrador === 1 ||
                master === 1 ||
                (administrador === 2 &&
                  gerente === 1 &&
                  gestor === 2 &&
                  analista === 2) ? (
                  <th className="acao">Ações</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {clienteLista ? (
                clienteLista.map((lista) => (
                  <tr key={lista.uuid}>
                    <td
                      onClick={() =>
                        handleClickOpenEdit(
                          lista.uuid,
                          pagination[0].pagina_atual
                        )
                      }
                      data-label="Nome"
                    >
                      {isLoading ? <Skeleton /> : lista.nome}
                    </td>
                    <td
                      onClick={() =>
                        handleClickOpenEdit(
                          lista.uuid,
                          pagination[0].pagina_atual
                        )
                      }
                      data-label="Data de criação"
                    >
                      {isLoading ? <Skeleton /> : lista.data_criacao?.br}
                    </td>
                    <td
                      onClick={() =>
                        handleClickOpenEdit(
                          lista.uuid,
                          pagination[0].pagina_atual
                        )
                      }
                      data-label="Unidade"
                    >
                      {isLoading ? <Skeleton /> : lista.empresa_nome}
                    </td>
                    <td
                      className={lista.status === 1 ? 'ativo' : 'inativo'}
                      onClick={() =>
                        handleClickOpenEdit(
                          lista.uuid,
                          pagination[0].pagina_atual
                        )
                      }
                      data-label="Status"
                    >
                      {isLoading ? <Skeleton /> : <div></div>}
                    </td>
                    {administrador === 1 ||
                    master === 1 ||
                    (administrador === 2 &&
                      gerente === 1 &&
                      gestor === 2 &&
                      analista === 2) ? (
                      <td
                        className="deletar"
                        onClick={() =>
                          handleClickOpenConfirm(lista.nome, lista.uuid)
                        }
                        data-label="Ações"
                      >
                        <p>Deletar</p>
                      </td>
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
          <S.ContainerPagination>
            {pagination.length ? (
              <Pagination
                showLastButton={true}
                showFirstButton={true}
                color="primary"
                count={pagination[0].ultima_pagina}
                page={Number(pagination[0].pagina_atual)}
                onChange={handleSelect}
              />
            ) : null}
          </S.ContainerPagination>

          <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Deletar
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Tem certeza que deseja deletar o cliente {nomeCliente || ''},
                essa ação não poderá ser desfeita
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => handleCloseConfirm()}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => handleDeletaConfirm(uuidCliente || '')}
                color="secondary"
              >
                Deletar
              </Button>
            </DialogActions>
          </Dialog>
        </S.Container>
      )}
    </>
  );
}
