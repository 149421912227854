import * as S from './styles';
import { useState, useEffect } from 'react';
import { useAcao } from '../../../hooks/acao';
import CloseIcon from '@material-ui/icons/Close';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ButtonGroup,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';
import InputMask from 'react-input-mask';

import { useParams } from 'react-router-dom';

import api from '../../../services/api';

export function SearchAcao({ setBusca, busca }) {
  const { id } = useParams();
  const { searchAcao } = useAcao();
  const [origemListaAtiva, setOrigemListaAtiva] = useState([]);
  const [clienteListaAtiva, setClienteListaAtiva] = useState([]);
  const [usuarioLista, setUsuarioLista] = useState([]);

  const [acao, setAcao] = useState('');
  const [problema, setProblema] = useState('');
  const [responsavel, setResponsavel] = useState('');
  const [pasta, setPasta] = useState('');
  const [status, setStatus] = useState('');
  const [inicioPrevistoInico, setInicioPrevistoInico] = useState('');
  const [inicioPrevistoFinal, setInicioPrevistoFinal] = useState('');
  const [conclusaoPrevistaInicio, setConclusaoPrevistaInicio] = useState('');
  const [conclusaoPrevistaFinal, setConclusaoPrevistaFinal] = useState('');
  const [inicio, setInicio] = useState('');
  const [conclusao, setConclusao] = useState('');
  const [origem, setOrigem] = useState('');
  const [codigo, setCodigo] = useState('');
  const [idAcao, setIdAcao] = useState('');

  const [isLoadingOrigem, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get('/origem?status=1')
      .then(({ data }) => {
        setLoading(false);
        setOrigemListaAtiva(data);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally((response) => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get('/cliente?status=1')
      .then(({ data }) => {
        setLoading(false);
        setClienteListaAtiva(data.itens);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally((response) => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get('/usuario?status=1')
      .then(({ data }) => {
        setLoading(false);
        setUsuarioLista(data.itens);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally((response) => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePesquisa = async (event) => {
    event.preventDefault();

    let data = {
      idAcao,
      acao,
      problema,
      responsavel,
      pasta,
      inicioPrevistoInico,
      inicioPrevistoFinal,
      conclusaoPrevistaInicio,
      conclusaoPrevistaFinal,
      inicio,
      conclusao,
      origem,
      status,
      codigo
    };

    await searchAcao(data, id);
    setBusca(false);
  };

  return (
    <Dialog
      open={busca}
      onClose={() => setBusca(false)}
      className="modal_dialog"
    >
      <S.Container onSubmit={handlePesquisa}>
        <DialogTitle className="titulo">
          Pesquise
          <IconButton className="fecha" onClick={() => setBusca(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className="grid_container">
          <Grid container>
            <Grid item xs={12}>
              <S.Column>
                <FormControl className="form_control">
                  <TextField
                    type="number"
                    variant="outlined"
                    label={'ID'}
                    value={idAcao}
                    placeholder="ID"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onChange={(event) => setIdAcao(event.target.value)}
                  />
                </FormControl>
                <FormControl className="form_control">
                  <TextField
                    variant="outlined"
                    label={'Ação'}
                    value={acao}
                    placeholder="Ação"
                    onChange={(event) => setAcao(event.target.value)}
                  />
                </FormControl>
                <FormControl className="form_control">
                  <TextField
                    variant="outlined"
                    label={'Problema'}
                    value={problema}
                    placeholder="Problema"
                    onChange={(event) => setProblema(event.target.value)}
                  />
                </FormControl>
                <FormControl className="form_control" variant="outlined">
                  <InputLabel id="demo-simple-select-required-label">
                    Responsável
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setResponsavel(event.target.value)}
                    value={responsavel}
                    label={'Responsável'}
                    placeholder="Responsável"
                  >
                    <MenuItem value="">
                      <em>
                        {isLoadingOrigem === true
                          ? 'Carregando lista'
                          : 'Selecione uma origem'}
                      </em>
                    </MenuItem>
                    {usuarioLista
                      ? usuarioLista.map((lista) => (
                          <MenuItem
                            key={`status${lista.uuid}`}
                            value={lista.uuid}
                          >
                            {lista.nome}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
                <FormControl className="form_control">
                  <TextField
                    variant="outlined"
                    label={'Código da auditoria'}
                    value={codigo}
                    placeholder="Código da auditoria"
                    onChange={(event) => setCodigo(event.target.value)}
                  />
                </FormControl>
                {/* <FormControl  className="form_control" variant="outlined"  >
                                    <InputLabel id="demo-simple-select-required-label">Origem</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={event => setOrigem(event.target.value)}
                                        value={origem}
                                        label={'Origem'}
                                        placeholder="Origem"
                                    >
                                        <MenuItem value="">
                                            <em>{isLoadingOrigem === true ? 'Carregando lista' : 'Selecione uma origem'}</em>
                                        </MenuItem>
                                        {origemListaAtiva?.itens ? origemListaAtiva?.itens.map(lista => (
                                            <MenuItem key={`status${lista.uuid}`} value={lista.id} >{lista.titulo}</MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>  */}

                <S.Row>
                  <FormControl className="form_control">
                    <InputMask
                      type="text"
                      mask="99/99/9999"
                      placeholder="Início previsto"
                      onChange={(event) =>
                        setInicioPrevistoInico(event.target.value)
                      }
                      value={inicioPrevistoInico}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          label={'Início previsto'}
                        />
                      )}
                    </InputMask>
                  </FormControl>
                  <span className="ate">até</span>
                  <FormControl className="form_control">
                    <InputMask
                      type="text"
                      mask="99/99/9999"
                      placeholder="Início previsto"
                      onChange={(event) =>
                        setInicioPrevistoFinal(event.target.value)
                      }
                      value={inicioPrevistoFinal}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          label={'Início previsto'}
                        />
                      )}
                    </InputMask>
                  </FormControl>
                </S.Row>

                <S.Row>
                  <FormControl className="form_control">
                    <InputMask
                      type="text"
                      mask="99/99/9999"
                      placeholder="Conclusão prevista"
                      onChange={(event) =>
                        setConclusaoPrevistaInicio(event.target.value)
                      }
                      value={conclusaoPrevistaInicio}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          label={'Conclusão prevista'}
                        />
                      )}
                    </InputMask>
                  </FormControl>
                  <span className="ate">até</span>
                  <FormControl className="form_control">
                    <InputMask
                      type="text"
                      mask="99/99/9999"
                      placeholder="Conclusão prevista"
                      onChange={(event) =>
                        setConclusaoPrevistaFinal(event.target.value)
                      }
                      value={conclusaoPrevistaFinal}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          label={'Conclusão prevista'}
                        />
                      )}
                    </InputMask>
                  </FormControl>
                </S.Row>

                <S.Row>
                  <FormControl className="form_control">
                    <InputMask
                      type="text"
                      mask="99/99/9999"
                      placeholder="Início previsto"
                      onChange={(event) => setInicio(event.target.value)}
                      value={inicio}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          label={'Início previsto'}
                        />
                      )}
                    </InputMask>
                  </FormControl>
                  <span className="ate">até</span>
                  <FormControl className="form_control">
                    <InputMask
                      type="text"
                      mask="99/99/9999"
                      placeholder="Conclusão prevista"
                      onChange={(event) => setConclusao(event.target.value)}
                      value={conclusao}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          variant="outlined"
                          label={'Conclusão prevista'}
                        />
                      )}
                    </InputMask>
                  </FormControl>
                </S.Row>

                <FormControl variant="outlined" className="form_control">
                  <InputLabel id="demo-simple-select-required-label">
                    Pasta
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setPasta(event.target.value)}
                    value={pasta}
                    label={'Pasta'}
                    placeholder="Pasta"
                  >
                    <MenuItem value="">
                      <em>Selecione uma pasta</em>
                    </MenuItem>
                    {clienteListaAtiva
                      ? clienteListaAtiva.map((lista) => (
                          <MenuItem
                            key={`status${lista.uuid}`}
                            value={lista.uuid}
                          >
                            {lista.nome}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>

                <FormControl className="form_control" variant="outlined">
                  <InputLabel id="demo-simple-select-required-label">
                    Status
                  </InputLabel>
                  <Select
                    variant="outlined"
                    onChange={(event) => setStatus(event.target.value)}
                    value={status}
                    label={'Status'}
                    placeholder="Status"
                  >
                    <MenuItem key="1" value="">
                      <em>Selecione um status</em>
                    </MenuItem>
                    <MenuItem key="2" value="1">
                      <em>Pendente</em>
                    </MenuItem>
                    <MenuItem key="3" value="2">
                      <em>Aceita</em>
                    </MenuItem>
                    <MenuItem key="4" value="3">
                      <em>Iniciada</em>
                    </MenuItem>
                    <MenuItem key="5" value="4">
                      <em>Concluída</em>
                    </MenuItem>
                    <MenuItem key="6" value="5">
                      <em>Cancelada</em>
                    </MenuItem>
                    <MenuItem key="7" value="6">
                      <em>Não aceita</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </S.Column>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="container_acao">
          <ButtonGroup
            orientation="horizontal"
            color="primary"
            className="buttonGroup"
            aria-label="vertical outlined primary button group"
          >
            <Button className="botao edit" variant="contained" type="submit">
              Pesquisar
            </Button>
          </ButtonGroup>
        </DialogActions>
      </S.Container>
    </Dialog>
  );
}
