import { useState } from 'react';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import { useOrigem } from '../../hooks/origem';
import Pagination from '@material-ui/lab/Pagination';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { Loading } from '../Loading/index';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function OrigemTable({
  onOpenAddModal,
  onOpenEditaModal,
  abreVisualizar
}) {
  const {
    origemLista,
    origemPage,
    isLoading,
    pagination,
    deletarOrigem,
    getOrigemPorUuid
  } = useOrigem();

  const [openConfirm, setOpenConfirmarDeletar] = useState(false);
  const [nomeOrigem, setNomeOrigem] = useState('');
  const [uuidOrigem, setUuidOrigem] = useState('');

  const handleSelect = async (event, value) => {
    await origemPage(value);
  };

  const handleClickOpenView = async (uuid) => {
    await getOrigemPorUuid(uuid);
    abreVisualizar();
  };

  function onOpenNewUserModal() {
    onOpenAddModal();
  }

  const handleClickOpenConfirm = async (nome, uuid) => {
    setOpenConfirmarDeletar(true);
    setNomeOrigem(nome);
    setUuidOrigem(uuid);
  };

  const handleCloseConfirm = async () => {
    setOpenConfirmarDeletar(false);
    setNomeOrigem('');
    setUuidOrigem('');
  };

  const handleDeletaConfirm = async (uuid) => {
    setOpenConfirmarDeletar(false);
    await deletarOrigem(uuid);
    setNomeOrigem('');
    setUuidOrigem('');
  };

  let gerente = Number(localStorage.getItem('GTPROWORK'));
  let master = Number(localStorage.getItem('MMPROWORK'));
  let administrador = Number(localStorage.getItem('MPROWORK'));

  return (
    <S.Container>
      {administrador === 1 || gerente === 1 || master === 1 ? (
        <button type="button" onClick={onOpenNewUserModal}>
          Nova Origem
        </button>
      ) : null}
      {isLoading ? <Loading /> : null}
      <div className="totalItens">
        <h1>Total de registros: {pagination[0]?.total}</h1>
      </div>
      <table>
        <thead>
          <tr>
            <th>Título</th>
            <th>Data de criação</th>
            <th>Unidade</th>
            <th className="status">Status</th>
            <th className="acao">Ações</th>
          </tr>
        </thead>
        <tbody>
          {origemLista?.itens ? (
            origemLista?.itens.map((lista) => (
              <tr key={lista.uuid}>
                <td
                  onClick={() =>
                    handleClickOpenView(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Título"
                >
                  {isLoading ? <Skeleton /> : lista.titulo}
                </td>
                <td
                  onClick={() =>
                    handleClickOpenView(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Data de criação"
                >
                  {isLoading ? <Skeleton /> : lista.data_criacao?.br}
                </td>
                <td
                  onClick={() =>
                    handleClickOpenView(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Unidade"
                >
                  {isLoading ? <Skeleton /> : lista.empresa_nome}
                </td>
                <td
                  className={lista.status === 1 ? 'ativo' : 'inativo'}
                  onClick={() =>
                    handleClickOpenView(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Status"
                >
                  {isLoading ? <Skeleton /> : <div></div>}
                </td>
                <td
                  className="deletar"
                  onClick={() =>
                    handleClickOpenConfirm(lista.titulo, lista.uuid)
                  }
                  data-label="Ações"
                >
                  <p>Deletar</p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
      <S.ContainerPagination>
        {pagination.length ? (
          <Pagination
            showLastButton={true}
            showFirstButton={true}
            color="primary"
            count={pagination[0].ultima_pagina}
            page={Number(pagination[0].pagina_atual)}
            onChange={handleSelect}
          />
        ) : null}
      </S.ContainerPagination>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Deletar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja deletar o origem {nomeOrigem || ''}, essa
            ação não poderá ser desfeita
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleCloseConfirm()}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleDeletaConfirm(uuidOrigem || '')}
            color="secondary"
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  );
}
