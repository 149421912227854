import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
/* import * as FaIcons from 'react-icons/fa'; */
import { AssignmentInd, Assignment } from '@material-ui/icons';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <AiIcons.AiOutlineDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  },
  {
    title: 'Usuários',
    path: '/usuario',
    icon: <RiIcons.RiUserFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  },
  {
    title: 'Pastas',
    path: '/pasta',
    icon: <AssignmentInd />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  },
  // {
  //   title: 'Tarefas',
  //   path: '/tarefa',
  //   icon: <Assignment />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />
  // },
  {
    title: 'Agenda',
    path: '/agenda',
    icon: <Assignment />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
    // subNav: [
    //   /*  {
    //         title: "Gerenciar checklist",
    //         path: "/checklist",
    //         icon: <Assignment />,
    //       }, */
    //   /*  {
    //         title: "Minhas Checklist",
    //         path: "/minhas-checklists",
    //         icon: <FaIcons.FaTasks />,
    //       }, */
    //   {
    //     title: 'Agenda',
    //     path: '/agenda',
    //     icon: <AiIcons.AiFillCalendar />
    //   },
    //   {
    //     title: 'Origens',
    //     path: '/origem',
    //     icon: <AiIcons.AiOutlineDashboard />
    //   }
    // ]
  },
  {
    title: 'Questionários',
    path: '/questionario',
    icon: <AiIcons.AiFillCheckSquare />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Gerenciar questionario',
        path: '/questionario',
        icon: <AiIcons.AiFillCheckSquare />
      },
      {
        title: 'Cadastrar respostas',
        path: '/item-respostas',
        icon: <AiIcons.AiFillSnippets />
      }
      /*  {
            title: "Respostas",
            path: "/checklist-respostas",
            icon: <AiIcons.AiFillSnippets />,
          }, */
    ]
  },
  {
    title: 'Unidades',
    path: '/empresas',
    icon: <RiIcons.RiBriefcaseFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />
  }
];
