import React, {useState} from "react";
import {Box} from '@material-ui/core'
import { FormEditaCliente } from "../../components/FormEditaCliente/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar/index";
import {ListaHistoricoComponent} from "../../components/ListaHistoricoComponent/index";
import { PastaProvider } from "../../hooks/pasta";
import { HistoricoProvider } from "../../hooks/historico";
import { TarefaProvider } from "../../hooks/tarefa";


export function EditCliente() {
  const [abreModal, setAbreModal] = useState(false);
    
    function handleAbreModalHistorico(){
        setAbreModal(true);
    }

    function handleFechaHistoricoModal(){
        setAbreModal(false);
    }

    const master = Number(localStorage.getItem('MMPROWORK'));
    const administrador = Number(localStorage.getItem('MPROWORK'));
    const gerente = Number(localStorage.getItem('GTPROWORK'));
    const gestor = Number(localStorage.getItem('GSPROWORK'));
    const analista = Number(localStorage.getItem('ANPROWORK'));

    let titulo = gestor === 1 && (administrador === 2 || gerente === 2 || analista === 2 || master === 2) ? 'Gerenciar' : 'Editar pasta';

  return (
    <PastaProvider>
      <TarefaProvider>
        <HistoricoProvider>
        <div style={{display: 'flex'}}>
            <Sidebar activePage={'3'} />
            <div style={{width: '100%'}}>
                <Topbar pageTitle={titulo} />
                <Box className="container_admin">
                      <FormEditaCliente verModalHistorico={handleAbreModalHistorico}  />
                </Box>
            </div>
        </div>
        {abreModal ? 
        <ListaHistoricoComponent
            fecha={handleFechaHistoricoModal} 
            abre={abreModal}
        /> 
        : null }
        </HistoricoProvider>
      </TarefaProvider>
    </PastaProvider>
  );
};



