import { useState } from 'react';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import { useCheckList } from '../../hooks/checklist';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { Loading } from '../Loading/index';
import { SearchChecklist } from './SearchChecklist';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function CheckListTable({ onOpenAddModal }) {
  let history = useHistory();
  const {
    checklistLista,
    checklistPage,
    isLoading,
    pagination,
    deletarCheckList,
    downloadRelatorioChecklist
  } = useCheckList();

  const [openConfirm, setOpenConfirmarDeletar] = useState(false);
  const [nomeCheckList, setNomeCheckList] = useState('');
  const [uuidCheckList, setUuidCheckList] = useState('');
  const [busca, setBusca] = useState(false);

  const handleSelect = async (event, value) => {
    await checklistPage(value);
  };

  const handleClickOpenEdit = async (uuid) => {
    history.push('/questionario/' + uuid);
  };

  function onOpenNewUserModal() {
    onOpenAddModal();
  }

  const handleClickOpenConfirm = async (nome, uuid) => {
    setOpenConfirmarDeletar(true);
    setNomeCheckList(nome);
    setUuidCheckList(uuid);
  };

  const handleCloseConfirm = async () => {
    setOpenConfirmarDeletar(false);
    setNomeCheckList('');
    setUuidCheckList('');
  };

  const handleDeletaConfirm = async (uuid) => {
    setOpenConfirmarDeletar(false);
    await deletarCheckList(uuid);
    setNomeCheckList('');
    setUuidCheckList('');
  };

  const handleDownloadRelatorio = async () => {
    await downloadRelatorioChecklist();
  };

  return (
    <S.Container>
      <button type="button" onClick={onOpenNewUserModal}>
        Novo Questionário
      </button>
      <button type="button" className="busca" onClick={() => setBusca(true)}>
        Buscar Questionário
      </button>
      <button
        className="buttonRelatorio"
        type="button"
        onClick={() => handleDownloadRelatorio()}
      >
        <span>Download Questionários</span>
      </button>

      <div className="totalItens">
        <h1>Total de registros: {pagination[0]?.total}</h1>
      </div>

      <table>
        <thead>
          <tr>
            <th>Título</th>
            <th>Data de criação</th>
            <th>Unidade</th>
            <th className="status">Status</th>
            <th className="acao">Ações</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <Loading />
          ) : (
            checklistLista.map((lista) => (
              <tr key={lista.uuid}>
                <td
                  onClick={() =>
                    handleClickOpenEdit(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Título"
                >
                  {isLoading ? <Skeleton /> : lista.titulo}
                </td>
                <td
                  onClick={() =>
                    handleClickOpenEdit(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Data de criação"
                >
                  {isLoading ? <Skeleton /> : lista.data_criacao?.br}
                </td>
                <td
                  onClick={() =>
                    handleClickOpenEdit(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Empresa"
                >
                  {isLoading ? <Skeleton /> : lista.empresa_nome}
                </td>
                <td
                  className={lista.status === 1 ? 'ativo' : 'inativo'}
                  onClick={() =>
                    handleClickOpenEdit(lista.uuid, pagination[0].pagina_atual)
                  }
                  data-label="Status"
                >
                  {isLoading ? <Skeleton /> : <div></div>}
                </td>
                <td
                  className="deletar"
                  onClick={() =>
                    handleClickOpenConfirm(lista.titulo, lista.uuid)
                  }
                  data-label="Ações"
                >
                  <p>Deletar</p>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <S.ContainerPagination>
        {pagination.length ? (
          <Pagination
            showLastButton={true}
            showFirstButton={true}
            color="primary"
            count={pagination[0].ultima_pagina}
            page={Number(pagination[0].pagina_atual)}
            onChange={handleSelect}
          />
        ) : null}
      </S.ContainerPagination>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Deletar
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja deletar o questionário {nomeCheckList || ''},
            essa ação não poderá ser desfeita
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleCloseConfirm()}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleDeletaConfirm(uuidCheckList || '')}
            color="secondary"
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
      <SearchChecklist setBusca={setBusca} busca={busca} />
    </S.Container>
  );
}
